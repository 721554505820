import * as types from '../../../actionsTypes/dashboard/settings/companyTypes'
import { initialValuesCompany as initialValues } from '../../../../helpers/variablesInitialValues'

const initialState = {
	perPage: 10,
	page: 0,
	search: '',
	pages: 0,
	arrayList: [],
	arrayListToDropdown: [],
	isOpenModal: false,
	isLoadingTable: true,
	isLoadingForm: false,
	formType: '',
	formData: initialValues
}

const companyReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.PER_PAGE_COMPANY:
			return {
				...state,
				perPage: payload
			}
		
		case types.PAGE_COMPANY:
			return {
				...state,
				page: payload
			}
		
		case types.SEARCH_COMPANY:
			return {
				...state,
				search: payload
			}
		
		case types.PAGES_COMPANY:
			return {
				...state,
				pages: payload
			}
		
		case types.ARRAY_LIST_COMPANY:
			return {
				...state,
				arrayList: payload
			}
		
		case types.ARRAY_LIST_TO_DROPDOWN_COMPANY:
			return {
				...state,
				arrayListToDropdown: payload
			}
		
		case types.IS_OPEN_MODAL_COMPANY:
			return {
				...state,
				isOpenModal: payload
			}
		
		case types.IS_LOADING_TABLE_COMPANY:
			return {
				...state,
				isLoadingTable: payload
			}
		
		case types.IS_LOADING_FORM_COMPANY:
			return {
				...state,
				isLoadingForm: payload
			}
		
		case types.FORM_TYPE_COMPANY:
			return {
				...state,
				formType: payload
			}
		
		case types.FORM_DATA_COMPANY:
			return {
				...state,
				formData: payload
			}
		
		default:
			return state
	}
}

export default companyReducer
