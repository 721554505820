import * as types from '../../../actionsTypes/dashboard/settings/userTypes'
import { initialValuesUser as initialValues } from '../../../../helpers/variablesInitialValues'

const initialState = {
	perPage: 10,
	page: 0,
	search: '',
	pages: 0,
	arrayList: [],
	arrayDropdown: [],
	isOpenModal: false,
	isLoadingTable: true,
	isLoadingForm: false,
	formType: '',
	formData: initialValues
}

const userReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.PER_PAGE_USER:
			return {
				...state,
				perPage: payload
			}
		
		case types.PAGE_USER:
			return {
				...state,
				page: payload
			}
		
		case types.SEARCH_USER:
			return {
				...state,
				search: payload
			}
		
		case types.PAGES_USER:
			return {
				...state,
				pages: payload
			}
		
		case types.ARRAY_LIST_USER:
			return {
				...state,
				arrayList: payload
			}
		
		case types.ARRAY_LIST_USER:
			return {
				...state,
				arrayDropdown: payload
			}
		
		case types.IS_OPEN_MODAL_USER:
			return {
				...state,
				isOpenModal: payload
			}
		
		case types.IS_LOADING_TABLE_USER:
			return {
				...state,
				isLoadingTable: payload
			}
		
		case types.IS_LOADING_FORM_USER:
			return {
				...state,
				isLoadingForm: payload
			}
		
		case types.FORM_TYPE_USER:
			return {
				...state,
				formType: payload
			}
		
		case types.FORM_DATA_USER:
			return {
				...state,
				formData: payload
			}
		
		default:
			return state
	}
}

export default userReducer
