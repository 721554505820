import * as types from '../../actionsTypes/dashboard/sidebarTypes'

const initialState = {
	isOpen: false,
	isLoading: false
}

const sidebarReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.IS_OPEN_SIDEBAR:
			return {
				...state,
				isOpen: payload
			}
			
		case types.IS_LOADING_SIDEBAR:
			return {
				...state,
				isLoading: payload
			}
		
		default:
			return state
	}
}

export default sidebarReducer
