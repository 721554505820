const ROOT = 'auth/'

export default {
  signIn: `${ROOT}login`,
  verification: `${ROOT}verification`,
  resendVerification: `${ROOT}resend-verification`,
  forgotPassword: `${ROOT}forgot-password`,
  passwordVerification: `${ROOT}password-verification`,
  passwordResendVerification: `${ROOT}password-resend-verification`,
  passwordReset: `${ROOT}password-reset`,
  signOut: `${ROOT}logout`
}
