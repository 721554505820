import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Feedback from '../../../../../components/mui/Feedback'
import FormComponent from './FormComponent'
import { setIsOpenModal } from '../../../../../redux/actions/dashboard/settings/permissions/permissionAction'
import { Permission } from '../../../../../services/permissions/Permission'

const ModalComponent = () => {
	const dispatch = useDispatch()
	const isOpenModal = useSelector( state => state.permission.isOpenModal)
	const formType = useSelector( state => state.permission.formType)
	
	const handleCloseModal = () => dispatch( setIsOpenModal(false) )
	
	return (
		<Feedback.Modal
			maxWidth="sm"
			isOpen={isOpenModal}
			isClose={handleCloseModal}
			title="Captación de permisos"
		>
			<Permission
				permission={formType === 'store' ? 'permissions.store' : 'permissions.show' }
			>
				<FormComponent />
			</Permission>
		</Feedback.Modal>
	)
}

export default ModalComponent
