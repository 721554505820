import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import Inputs from '../../../../components/mui/Inputs'
import Layout from '../../../../components/mui/Layout'
import { forgotPassword } from '../../../../redux/actions/auth/forgotPasswordAction'
import { initialValuesForgotPassword as initialValues } from '../../../../helpers/variablesInitialValues'

const FormComponent = () => {
	const dispatch = useDispatch()
	const isLoadingForm = useSelector( state => state.forgotPassword.isLoadingForm)
	
	const validationSchema = () => (
		Yup.object({
			email: Yup.string()
				.email('El email no es válido')
				.required('El email es requerido')
		})
	)
	
	const hanbleSubmit = async ({ email }, { setSubmitting, resetForm, setValues }) => {
		await setSubmitting(false)
		
		await dispatch( forgotPassword({ email: email }) )
		
		await resetForm({ values: initialValues })
		await setValues(initialValues)
	}
	
	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={hanbleSubmit}
		>
			{({
			  values,
			  errors,
			  handleChange,
			  handleBlur,
			  isValid,
			  dirty
		  }) => (
				<Form noValidate>
					<Inputs.TextBox
						type="email"
						variant="outlined"
						size="small"
						name="email"
						label="Email"
						placeholder="Ingrese email..."
						hasLabelFixed={true}
						value={values.email}
						onChange={handleChange}
						onBlur={handleBlur}
						hasError={errors.email ? true : false}
						errorMessage={errors.email ? errors.email : ''}
						className="font-family-roboto-medium text-dark fs-5 w-100"
					/>
					
					<Inputs.LoadingButton
						isDisabled={!(dirty && isValid)}
						variant="contained"
						color="primary"
						isLoading={isLoadingForm}
						isLoadingPosition="start"
						startIcon={<Layout.Icons.MailIcon height={18} width={18} />}
						label="Cambiar contraseña"
						type="submit"
						className="font-family-roboto-regular fs-6 mt-2 w-100"
					/>
				</Form>
			)}
		</Formik>
	)
}

export default FormComponent
