import Modal from './Modal'
import ModalLoading from './ModalLoading'
import Skeleton from './Skeleton'

const index = {
	Modal,
	ModalLoading,
	Skeleton
}

export default index
