import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'
import Feedback from '../../../../../components/mui/Feedback'
import Inputs from '../../../../../components/mui/Inputs'
import Layout from '../../../../../components/mui/Layout'
import PermissionsRowComponent from '../../RolePage/components/PermissionsRowComponent'
import { getPermissionArrayToDropdown } from '../../../../../redux/actions/dashboard/settings/permissions/permissionAction'
import { setFormData, setUserAssignPermissionsById } from '../../../../../redux/actions/dashboard/settings/users/userAction'
import { Permission } from '../../../../../services/permissions/Permission'

const PermissionsComponent = ({ name, isOpen, setOpen }) => {
	const dispatch = useDispatch()
	const isLoadingPermission = useSelector(state => state.permission.isLoadingForm)
	const isLoadingForm = useSelector(state => state.user.isLoadingForm)
	const permissions = useSelector(state => state.permission.arrayListToDropdown)
	const formData = useSelector(state => state.user.formData)
	
	const getPermissionsArray = async () => isOpen && await dispatch( getPermissionArrayToDropdown(formData) )
	
	useEffect(() => {
		getPermissionsArray()
	}, [isOpen])
	
	const handleChange = async (id, name) => {
		const array = formData.permissions
		const find = array.find(item => item.name === name)
		
		if (find) {
			array.forEach((item, index, map) => {
				if (item.name === name) {
					map.splice(index, 1)
				}
			})
		} else {
			array.push({ id: id, name: name })
		}
		
		const param = {
			id: formData.id,
			avatar: formData.avatar,
			first_name: formData.first_name,
			last_name: formData.last_name,
			email: formData.email,
			mobile: formData.mobile,
			password: formData.password,
			password_confirm: formData.password_confirm,
			realState: formData.realState,
			role: formData.role,
			permissions: array
		}

		dispatch( setFormData(param) )
	}
	
	const handleAssignPermissionById = async () => {
		await dispatch( setUserAssignPermissionsById(formData.id, formData) )
		!isLoadingForm && await handleCloseModal()
	}
	
	const handleCloseModal = async () => await setOpen(false)
	
	return (
		<Feedback.Modal
			maxWidth="md"
			isOpen={isOpen}
			isClose={handleCloseModal}
			title={`Asignar permisos al usuario ${name}`}
			className="vh-100"
			hasAction={true}
			contentActions={
				<Permission
					permission="users.assignPermissions"
				>
					<Inputs.LoadingButton
						variant="contained"
						color="primary"
						isLoading={isLoadingForm}
						isLoadingPosition="start"
						startIcon={<Layout.Icons.EditIcon height={18} width={18} />}
						label="Guardar"
						type="button"
						className="font-family-roboto-regular fs-6"
						onClick={handleAssignPermissionById}
					/>
				</Permission>
			}
		>
			<div className="row">
				{isLoadingPermission && (
					<div className="col-sm-12 vh-100 bottom-100 text-center">
						<CircularProgress
							style={{height: '32px', width: '32px'}}
							color="inherit"
						/>
					</div>
				)}
				{!isLoadingPermission && (
					permissions.length > 0 && (
						permissions.map(({ group, items }, number) => (
							<PermissionsRowComponent
								key={number}
								group={group}
								items={items}
								handleChange={handleChange}
							/>
						))
					)
				)}
			</div>
		</Feedback.Modal>
	)
}

PermissionsComponent.propTypes = {
	name: PropTypes.string.isRequired,
	isOpen: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired
}

export default PermissionsComponent
