import React from 'react'
import { useSelector } from 'react-redux'
import Navigation from '../../../components/mui/Navigation'
import Feedback from '../../../components/mui/Feedback'
import Header from './header'
import Sidebar from './sidebar'
import ProtectedRoutes from '../../../routes/ProtectedRoutes'
import './Layout.scss'

const LayoutPage = () => {
	const isLoading = useSelector( state  => state.loading.isLoading)
	const isOpen = useSelector( state  => state.sidebar.isOpen)
	
	return (
		<div className="wrapper">
			<Header />
			
			<div className={`sidebar ${isOpen && 'sidebar-open'} bg-white position-fixed animate__animated`}>
				<Sidebar />
			</div>
			
			<div className={`mainContent ${!isOpen && 'mainContent-open'} d-block animate__animated`}>
				<div className="content d-block position-relative">
					<ProtectedRoutes />
				</div>
			</div>
			
			<Navigation.ScrollTop />
			
			<Feedback.ModalLoading
				maxWidth="xs"
				isOpen={isLoading}
				title="Cargando..."
				content="Espere un momento por favor..."
			/>
		</div>
	)
}

export default LayoutPage
