import ReactJwtDecode from 'jwt-decode'
import { initialValuesAuth as initialValues } from '../../../helpers/variablesInitialValues'
import * as types from '../../actionsTypes/auth/authTypes'
import { setIsLoading } from '../loadingAction'
import { setIsLoadingSidebar } from '../dashboard/sidebarAction'
import { setPermissionReset } from '../dashboard/settings/permissions/permissionAction'
import { setRoleReset } from '../dashboard/settings/roles/roleAction'
import { setUserReset } from '../dashboard/settings/users/userAction'
import { setProfileReset } from '../dashboard/settings/users/profileAction'
import { setSignIn, setSignOut } from './services/authServices'
import errorServices from '../../../services/errors'
import avatar from '../../../assets/images/avatar-default.jpg'

export const signIn = param => (
  async (dispatch) => {
    await dispatch( setIsLoadingSidebar(true) )
    await dispatch( setIsLoadingForm(true) )
    await dispatch( setIsLoading(true) )
  
    await setSignIn(param).then(({ data }) => {
      dispatch( setIsAuthenticated(true) )
      dispatch( setTokenType(data.data.token_type) )
      dispatch( setAccessToken(data.data.access_token) )
      dispatch( setExpiresAt(data.data.expires_at) )
      
      const object = {
        firstName: data.data.user_data.first_name,
        lastName: data.data.user_data.last_name,
        email: data.data.user_data.email,
        realState: data.data.user_data.realstate,
        avatar: data.data.user_data.avatar === null ? avatar : data.data.user_data.avatar,
      }
      
      const decode = ReactJwtDecode(data.data.access_token)
      
      if (decode) object.roles = decode.roles
      
      dispatch( setUserData(object) )
    }).catch(error => errorServices(error))
    
    await dispatch( setIsLoadingSidebar(false) )
    await dispatch( setIsLoadingForm(false) )
    await dispatch( setIsLoading(false) )
  }
)

export const signOut = () => {
  return async (dispatch) => {
    await dispatch( setIsLoadingForm(true) )
  
    await setSignOut().then(response => {
      dispatch( setReset() )
      dispatch( setPermissionReset() )
      dispatch( setRoleReset() )
      dispatch( setUserReset() )
      dispatch( setProfileReset() )
    }).catch(error => errorServices(error))
    
    await dispatch( setIsLoadingForm(false) )
  }
}

export const setReset = () => (
  async (dispatch) => {
    await dispatch( setIsAuthenticated(false) )
    await dispatch( setTokenType('') )
    await dispatch( setAccessToken('') )
    await dispatch( setExpiresAt('') )
    await dispatch( setUserData(initialValues) )
  }
)

export const setIsLoadingForm = value => ({
  type: types.IS_LOADING_FORM_AUTH,
  payload: value
})

export const setIsAuthenticated = value => ({
  type: types.IS_AUTHENTICATED,
  payload: value
})

export const setTokenType = value => ({
  type: types.TOKEN_TYPE,
  payload: value
})

export const setAccessToken = value => ({
  type: types.ACCESS_TOKEN,
  payload: value
})

export const setExpiresAt = value => ({
  type: types.EXPIRES_AT,
  payload: value
})

export const setUserData = value => ({
  type: types.USER_DATA,
  payload: value
})
