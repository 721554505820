import { initialValuesPermission as initialValues } from '../../../../../helpers/variablesInitialValues'
import * as types from '../../../../actionsTypes/dashboard/settings/permissionTypes'
import { setIsLoading } from '../../../loadingAction'
import { getArrayList, getArrayListToDropdown, setStore, getShowById, setUpdateById, setDeleteById } from './services/permissionService'
import { swalSuccess } from '../../../../../services/sweetalert'
import errorServices from '../../../../../services/errors'

export const getPermissionArray = param => (
	async (dispatch) => {
		await dispatch( setIsLoading(true) )
		
		await getArrayList(param).then(({ data }) => {
			dispatch( setPages(data.data.pages) )
			dispatch( setArrayList(data.data.records) )
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoading(false) )
	}
)

export const getPermissionArrayToDropdown = param => (
	async (dispatch) => {
		await dispatch( setIsLoadingForm(true) )
		
		await getArrayListToDropdown().then(({ data }) => {
			let object = []
			
			if (data.data.records.length > 0) {
				const array = data.data.records
				
				array.map(({ items }) => {
					const records = items

					records.map(({ name }, index) => {
						let isActive = false

						if (param.permissions.length > 0) {
							const find = param.permissions.find(item => item.name === name)

							isActive = find ? true : false
						}

						records[index].active = isActive
					})
				})
				
				object = array
			}
			
			dispatch( setArrayListToDropdown(object) )
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoadingForm(false) )
	}
)

export const setPermissionStore = data => (
	async (dispatch) => {
		await dispatch( setIsLoadingForm(true) )
		
		await setStore(data).then(({ data }) => {
			dispatch( setRefresh() )
			swalSuccess('Captación exitosa', data.data)
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoadingForm(false) )
	}
)

export const getPermissionShowById = id => (
	async (dispatch) => {
		await dispatch( setIsLoadingForm(true) )
		
		await getShowById(id).then(({ data }) => {
			const object = {
				id: data.data.id,
				name: data.data.name,
				publicName: data.data.public_name,
				description: data.data.description,
				group: data.data.group
			}
			
			dispatch( setFormData(object) )
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoadingForm(false) )
	}
)

export const setPermissionUpdateById = (id, param) => (
	async (dispatch) => {
		await dispatch( setIsLoadingForm(true) )
		
		await setUpdateById(id, param).then(({ data }) => {
			dispatch( setRefresh() )
			swalSuccess('Captación exitosa', data.data)
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoadingForm(false) )
	}
)

export const setPermissionDeleteById = id => (
	async (dispatch) => {
		await dispatch( setIsLoading(true) )
		
		await setDeleteById(id).then(({ data }) => {
			dispatch( setRefresh() )
			swalSuccess('Eliminación exitosa', data.data)
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoading(false) )
	}
)

const setRefresh = () => (
	async (dispatch) => {
		await dispatch( setFormType('') )
		await dispatch( setFormData(initialValues) )
		await dispatch( setIsLoadingTable(true) )
		await dispatch( setIsOpenModal(false) )
	}
)

export const setPermissionReset = () => (
	async (dispatch) => {
		await dispatch( setPerPage(10) )
		await dispatch( setPage(0) )
		await dispatch( setSearch('') )
		await dispatch( setPages(0) )
		await dispatch( setArrayList([]) )
		await dispatch( setArrayListToDropdown([]) )
		await dispatch( setFormType('') )
		await dispatch( setFormData(initialValues) )
		await dispatch( setIsOpenModal(false) )
		await dispatch( setIsLoadingTable(true) )
		await dispatch( setIsLoadingForm(false) )
	}
)

export const setPerPage = value => ({
	type: types.PER_PAGE_PERMISSION,
	payload: value
})

export const setPage = value => ({
	type: types.PAGE_PERMISSION,
	payload: value
})

export const setSearch = value => ({
	type: types.SEARCH_PERMISSION,
	payload: value
})

export const setPages = value => ({
	type: types.PAGES_PERMISSION,
	payload: value
})

export const setArrayList = value => ({
	type: types.ARRAY_LIST_PERMISSION,
	payload: value
})

export const setArrayListToDropdown = value => ({
	type: types.ARRAY_LIST_TO_DROPDOWN_PERMISSION,
	payload: value
})

export const setIsOpenModal = value => ({
	type: types.IS_OPEN_MODAL_PERMISSION,
	payload: value
})

export const setIsLoadingTable = value => ({
	type: types.IS_LOADING_TABLE_PERMISSION,
	payload: value
})

export const setIsLoadingForm = value => ({
	type: types.IS_LOADING_FORM_PERMISSION,
	payload: value
})

export const setFormType = value => ({
	type: types.FORM_TYPE_PERMISSION,
	payload: value
})

export const setFormData = value => ({
	type: types.FORM_DATA_PERMISSION,
	payload: value
})
