import * as types from '../../actionsTypes/auth/forgotPasswordTypes'

const initialState = {
	isLoadingForm: false
}

const forgotPasswordReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.IS_LOADING_FORM_FORGOT_PASSWORD:
			return {
				...state,
				isLoadingForm: payload
			}
		
		default:
			return state
	}
}

export default forgotPasswordReducer
