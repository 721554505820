import React, { useState } from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import Layout from '../Layout'

const TextBoxPassword = (props) => {
  const {
    variant,
    size = 'medium',
    name,
    label,
    placeholder,
    hasLabelFixed,
    value,
    onChange = null,
    onBlur = null,
    onKeyPress = null,
    hasError = false,
    errorMessage = '',
    ...more
  } = props
  const [isShow, setIsShow] = useState(false)

  const handleChange = () => setIsShow(!isShow)

  return (
    <>
      <TextField
        fullWidth
        type={isShow ? 'text' : 'password'}
        variant={variant}
        size={size}
        name={name}
        label={label}
        placeholder={placeholder}
        InputLabelProps={{
          shrink: hasLabelFixed,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment
              position="end"
              onClick={handleChange}
            >
              {isShow
                ? <Layout.Icons.VisibilityIcon className={hasError ? 'text-danger' : 'text-dark'} height={20} width={20} />
                : <Layout.Icons.VisibilityOffIcon className={hasError ? 'text-danger' : 'text-dark'} height={20} width={20} />
              }
            </InputAdornment>
          )
        }}
        defaultValue={value}
        onChange={onChange}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
        error={hasError}
        {...more}
      />
      {hasError && errorMessage !== "" && (
        <div className="mt-1 bg-grey-light border-l-4 border-danger p-2 font-family-roboto-regular fs-6 text-danger animate__animated animate__fadeIn">
          {errorMessage}
        </div>
      )}
    </>
  )
}

TextBoxPassword.propTypes = {
    variant: PropTypes.string.isRequired,
    size: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    hasLabelFixed: PropTypes.bool.isRequired,
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onKeyPress: PropTypes.func,
    hasError: PropTypes.bool,
    errorMessage: PropTypes.string
}

export default TextBoxPassword
