import Swal from 'sweetalert2'
// import useLogout from '../../hooks/useLogout'

export const swalSuccess = (title, message) => (
  Swal.fire({
    title: title,
    html: message,
    icon: 'success',
    allowOutsideClick: false,
    allowEscapeKey: false,
    showConfirmButton: true,
    confirmButtonColor: '#3085d6',
    confirmButtonText: 'Aceptar'
  })
)

export const swalError = (message, status = null) => {
  // if (status === 401) {
  //   useLogout()
  // }
  
  return Swal.fire({
    title: 'Error',
    html: message,
    icon: 'error',
    allowOutsideClick: false,
    allowEscapeKey: false,
    showConfirmButton: true,
    confirmButtonColor: '#3085d6',
    confirmButtonText: 'Aceptar'
  })
}

export const swalConfirm = message => (
  Swal.fire({
    title: '',
    html: message,
    icon: 'warning',
    allowOutsideClick: false,
    allowEscapeKey: false,
    showConfirmButton: true,
    confirmButtonColor: '#3085d6',
    confirmButtonText: 'Aceptar',
    showDenyButton: true,
    denyButtonText: 'Cancelar',
    reverseButtons: true
  }).then(result => {
    if (result.isDenied) {
      return false
    }
  })
)
