import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Layout from '../../../../../components/mui/Layout'
import TableRowComponent from './TableRowComponent'
import { getRoleArray, setPerPage, setPage, setSearch, setIsLoadingTable, setIsOpenModal, setFormType, setFormData } from '../../../../../redux/actions/dashboard/settings/roles/roleAction'
import { Permission } from '../../../../../services/permissions/Permission'
import { roleColumns as columns, perPages } from '../../../../../helpers/variablesColumns'
import { initialValuesRole as initialValues } from '../../../../../helpers/variablesInitialValues'

const TableComponent = () => {
	const dispatch = useDispatch()
	const isLoading = useSelector( state => state.loading.isLoading)
	const isLoadingTable = useSelector( state => state.role.isLoadingTable)
	const perPage = useSelector( state => state.role.perPage)
	const page = useSelector( state => state.role.page)
	const search = useSelector( state => state.role.search)
	const pages = useSelector( state => state.role.pages)
	const arrayList = useSelector( state => state.role.arrayList)
	
	const getArrayList = async () => {
		const param = {
			perPage: perPage,
			page: page,
			search: search === '' ? null : search
		}
		
		await dispatch( getRoleArray(param) )
	}
	
	useEffect(() => {
		isLoadingTable && getArrayList()
	}, [perPage, page, isLoadingTable])
	
	const handleChangePerPage = ({ target }) => dispatch( setPerPage(target.value) )
	
	const handleChangePage = (event, value) => dispatch( setPage(value) )
	
	const handleChangeSearch = ({ target }) => dispatch( setSearch(target.value) )
	
	const handleKeyPressArrayList = async ({ charCode }) => charCode === 13 && await getArrayList()
	
	const handleIsOpenModal = () => {
		dispatch( setFormData(initialValues) )
		dispatch( setFormType('store') )
		dispatch( setIsLoadingTable(false) )
		dispatch( setIsOpenModal(true) )
	}
	
	return (
		<>
			<Layout.TableSearch
				permissionList="roles.list"
				permissionButton="roles.store"
				value={search}
				handleChange={handleChangeSearch}
				handleIsOpenModal={handleIsOpenModal}
				handleKeyPressArrayList={handleKeyPressArrayList}
			/>
			<Permission
				permission="roles.list"
			>
				<Layout.TablePagination
					arrayList={arrayList.length}
					perPage={perPage}
					perPages={perPages}
					page={page}
					pages={pages}
					handleChangePerPage={handleChangePerPage}
					handleChangePage={handleChangePage}
				/>
				<Layout.Table>
					<Layout.TableHead
						columns={columns}
					/>
					<tbody>
					{isLoading && (
						<Layout.TableRowLoading
							columns={columns}
						/>
					)}
					{!isLoading && (
						<>
							{arrayList.length === 0 && (
								<Layout.TableRowNoFound
									colSpan={columns.length}
								/>
							)}
							{arrayList.length > 0 && (
								arrayList.map(({ id, name }, number) => (
									<TableRowComponent
										key={number}
										id={id}
										name={name}
									/>
								))
							)}
						</>
					)}
					</tbody>
				</Layout.Table>
				<Layout.TablePagination
					arrayList={arrayList.length}
					perPage={perPage}
					perPages={perPages}
					page={page}
					pages={pages}
					handleChangePerPage={handleChangePerPage}
					handleChangePage={handleChangePage}
				/>
			</Permission>
		</>
	)
}

export default TableComponent
