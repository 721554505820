import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Stack from '@mui/material/Stack'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Layout from '../../../../components/mui/Layout'
import Feedback from '../../../../components/mui/Feedback'
import ModalProfileComponent from '../../../dashboard/settings/UserPage/components/ModalProfileComponent'
import { signOut } from '../../../../redux/actions/auth/authAction'
import { setIsOpenSidebar } from '../../../../redux/actions/dashboard/sidebarAction'
import { setIsOpenModal } from '../../../../redux/actions/dashboard/settings/users/profileAction'
import { Permission } from '../../../../services/permissions/Permission'
import './Header.scss'

const Header = () => {
	const dispatch = useDispatch()
	const isLoadingForm = useSelector( state  => state.auth.isLoadingForm)
	const isOpen = useSelector( state  => state.sidebar.isOpen)
	const userData = useSelector( state  => state.auth.userData)
	const [anchorEl, setAnchorEl] = useState(null)
	
	const handleToggle = () => dispatch( setIsOpenSidebar(!isOpen) )
	
	const handleIsOpenMenu = event => setAnchorEl(event.currentTarget)
	
	const handleCloseMenu = () => setAnchorEl(null)
	
	const handleIsOpenModal = () => dispatch( setIsOpenModal(true) )
	
	const handleSignOut = async () => await dispatch( signOut() )
	
	return (
		<>
			<AppBar className="height-60 bg-white" position="fixed">
				<Toolbar>
					<Layout.IconButton
						color="inherit"
						size="large"
						onClick={handleToggle}
						edge="start"
						className={`animated animate__animated menu-responsive ${!isOpen && 'menu-responsive-open'}`}
					>
						<Layout.Icons.MenuIcon
							width={20}
							height={20}
							className="text-dark"
						/>
					</Layout.IconButton>
					<div className="position-absolute r-3">
						<Stack
							direction="row"
							spacing={2}
							onClick={handleIsOpenMenu}
						>
							<Layout.Avatar
								alt="avatar"
								src={userData.avatar}
								size="sm"
								icon={false}
							/>
							<Layout.IconButton
								color="inherit"
								size="large"
								aria-label="account of current user"
								aria-controls="menu-appbar"
								aria-haspopup="true"
							>
								<Layout.Icons.MoreIcon
									width={20}
									height={20}
									className="text-dark"
								/>
							</Layout.IconButton>
						</Stack>
						<Menu
							id="menu-appbar"
							anchorEl={anchorEl}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
							keepMounted
							transformOrigin={{
								vertical: 'top',
								horizontal: 'left',
							}}
							open={Boolean(anchorEl)}
							onClose={handleCloseMenu}
							sx={{
								display: { xs: 'block' },
							}}
						>
							<Permission
								permission="users.changeProfile"
							>
								<MenuItem onClick={handleIsOpenModal}>
									<ListItemIcon>
										<Layout.Icons.AdminPanelSettingsIcon
											height={20}
											width={20}
										/>
									</ListItemIcon>
									<ListItemText>Profile</ListItemText>
								</MenuItem>
								<Layout.Divider
									component="li"
									color="light"
								/>
							</Permission>
							<MenuItem onClick={handleSignOut}>
								<ListItemIcon>
									<Layout.Icons.ExitToAppIcon
										height={20}
										width={20}
									/>
								</ListItemIcon>
								<ListItemText>Salir</ListItemText>
							</MenuItem>
						</Menu>
					</div>
				</Toolbar>
			</AppBar>
			<Feedback.ModalLoading
				maxWidth="xs"
				isOpen={isLoadingForm}
				title="Cerrando sesión..."
				content="Espere un momento por favor..."
			/>
			<ModalProfileComponent />
		</>
	)
}

export default Header
