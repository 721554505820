import Avatar from './Avatar'
import Card from './Card'
import CardActions from './CardActions'
import CardContent from './CardContent'
import Chip from './Chip'
import Divider from './Divider'
import HeaderContent from './HeaderContent'
import IconButton from './IconButton'
import Icons from './Icons'
import Page from './Page'
import Pagination from './Pagination'
import Table from './Table'
import TableBody from './TableBody'
import TableHead from './TableHead'
import TablePagination from './TablePagination'
import TableRowLoading from './TableRowLoading'
import TableRowNoFound from './TableRowNoFound'
import TableSearch from './TableSearch'
import Tooltip from './Tooltip'

const index = {
	Avatar,
	Card,
	CardActions,
	CardContent,
	Chip,
	Divider,
	HeaderContent,
	IconButton,
	Icons,
	Page,
	Pagination,
	Table,
	TableBody,
	TableHead,
	TablePagination,
	TableRowLoading,
	TableRowNoFound,
	TableSearch,
	Tooltip
}

export default index
