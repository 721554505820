import React, { Fragment } from 'react'
import { Routes, Route } from 'react-router-dom'
import MainPage from '../pages/dashboard/MainPage'
import PermissionPage from '../pages/dashboard/settings/PermissionPage'
import RolePage from '../pages/dashboard/settings/RolePage'
import CompanyPage from '../pages/dashboard/settings/CompanyPage'
import UserPage from '../pages/dashboard/settings/UserPage'
import UnAuthorizationPage from '../pages/errors/UnAuthorizationPage'
import PermissionRoutes from '../services/permissions/PermissionRoutes'

const ProtectedRoutes = () => {
	const routes = [
		{
			permission: 'main.page',
			index: true,
			path: 'main',
			element: <MainPage />
		},
		{
			permission: 'permissions.page',
			index: false,
			path: 'settings/permissions',
			element: <PermissionPage />
		},
		{
			permission: 'roles.page',
			index: false,
			path: 'settings/roles',
			element: <RolePage />
		},
		{
			permission: 'companies.page',
			index: false,
			path: 'settings/companies',
			element: <CompanyPage />
		},
		{
			permission: 'users.page',
			index: false,
			path: 'settings/users',
			element: <UserPage />
		},
	]
	
	return (
		<Routes>
			{routes.length > 0 && (
				routes.map(({ permission, index, path, element}, number) => (
					<Fragment
						key={number}
					>
						<Route
							index={index}
							path={path}
							element={PermissionRoutes(permission) ? element : <UnAuthorizationPage />}
						/>
					</Fragment>
				))
			)}
		</Routes>
	)
}

export default ProtectedRoutes

