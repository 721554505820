export const initialValuesLogin = {
	email: '',
	password: ''
}

export const initialValuesForgotPassword = {
	email: ''
}

export const initialValuesPasswordReset = {
	password: '',
	passwordConfirm: ''
}

export const initialValuesAuth = {
	avatar: '',
	firstName: '',
	lastName: '',
	email: '',
	roles: []
}

export const initialValuesPermission = {
	id: '',
	name: '',
	publicName: '',
	description: '',
	group: ''
}

export const initialValuesRole = {
	id: '',
	name: '',
	permissions: []
}

export const initialValuesCompany = {
	id: '',
	name: '',
	email: '',
	mobile: '',
	phone: '',
	address: ''
}

export const initialValuesUser = {
	id: '',
	avatar: '',
	firstName: '',
	lastName: '',
	email: '',
	mobile: '',
	phone: '',
	address: '',
	password: '',
	passwordConfirm: '',
	role: '',
	permissions: []
}

export const initialValuesProfile = {
	avatar: '',
	firstName: '',
	lastName: '',
	email: '',
	mobile: '',
	phone: '',
	address: '',
	password: '',
	passwordConfirm: ''
}
