import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import CircularProgress from '@mui/material/CircularProgress'

const ModalLoading = ({ maxWidth, isOpen, title, content }) => {
	
	return (
		<Dialog
			disableEscapeKeyDown
			fullWidth={true}
			maxWidth={maxWidth}
			open={isOpen}
		>
			<DialogTitle>
				{title}
			</DialogTitle>
			<DialogContent dividers>
				<div className="d-inline-block">
					<CircularProgress
						style={{height: '32px', width: '32px'}}
						color="inherit"
					/>
				</div>
				<div className="position-absolute d-inline-block ml-3 mt-1">
					<div className="font-family-roboto-regular fs-6 text-secondary">
						{content}
					</div>
				</div>
			</DialogContent>
		</Dialog>
	)
}

ModalLoading.propTypes = {
	maxWidth: PropTypes.string.isRequired,
	isOpen: PropTypes.bool.isRequired,
	title: PropTypes.string.isRequired,
	content: PropTypes.string.isRequired
}

export default ModalLoading
