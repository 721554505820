import React from 'react'
import PropTypes from 'prop-types'
import MailOutline from '@mui/icons-material/MailOutline'

const MailOutlineIcon = ({ height, width, ...more }) => {

  return (
    <MailOutline
      height={height}
      width={width}
      {...more}
    />
  )
}

MailOutlineIcon.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired
}

export default MailOutlineIcon
