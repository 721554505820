import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import Inputs from '../../../../components/mui/Inputs'
import Layout from '../../../../components/mui/Layout'
import Feedback from '../../../../components/mui/Feedback'
import { signIn } from '../../../../redux/actions/auth/authAction'
import { initialValuesLogin as initialValues } from '../../../../helpers/variablesInitialValues'

const FormComponent = () => {
  const dispatch = useDispatch()
  const isLoading = useSelector( state => state.loading.isLoading)
  const isLoadingForm = useSelector( state => state.auth.isLoadingForm)
  const isAuthenticated = useSelector( state  => state.auth.isAuthenticated)
  
  const validationSchema = () => (
    Yup.object({
      email: Yup.string()
        .email('El email no es válido')
        .required('El email es requerido'),
      password: Yup.string()
        .min(6, 'La contraseña debe contener al menos 6 caracteres')
        .required('La contraseña es requerida')
    })
  )

  const hanbleSubmit = async (values, { setSubmitting, resetForm, setValues }) => {
    await setSubmitting(false)
    
    await dispatch( signIn(values) )
    
    isAuthenticated && await resetForm({ values: initialValues})
    isAuthenticated && await setValues(initialValues)
  }
  
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={hanbleSubmit}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          isValid,
          dirty
        }) => (
          <Form noValidate>
            <Inputs.TextBox
              type="email"
              variant="outlined"
              size="small"
              name="email"
              label="Email"
              placeholder="Ingrese email..."
              hasLabelFixed={true}
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              hasError={errors.email ? true : false}
              errorMessage={errors.email ? errors.email : ''}
              className="font-family-roboto-medium text-dark fs-5 w-100"
            />
            
            <Inputs.TextBoxPassword
              variant="outlined"
              size="small"
              name="password"
              label="Contraseña"
              placeholder="Ingrese contraseña..."
              hasLabelFixed={true}
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              hasError={errors.password ? true : false}
              errorMessage={errors.password ? errors.password : ''}
              className="font-family-roboto-medium text-dark fs-5 w-100 mt-3"
            />
  
            <Inputs.LoadingButton
              isDisabled={!(dirty && isValid)}
              variant="contained"
              color="primary"
              isLoading={isLoadingForm}
              isLoadingPosition="start"
              startIcon={<Layout.Icons.LockOpenIcon height={18} width={18} />}
              label="Iniciar sesión"
              type="submit"
              className="font-family-roboto-regular fs-6 mt-2 w-100"
            />
          </Form>
        )}
      </Formik>
      <Feedback.ModalLoading
        maxWidth="xs"
        isOpen={isLoading}
        title="Iniciando sesión..."
        content="Espere un momento por favor..."
      />
    </>
  )
}

export default FormComponent
