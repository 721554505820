import React from 'react'
import Fab from '@mui/material/Fab'
import Zoom from '@mui/material/Zoom'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import Layout from '../Layout'

const ScrollTop = () => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100
  })

  const handleClick = () => {
    window.scroll({top: 0, left: 0, behavior: 'smooth' })
  }

  return (
    <Zoom in={trigger}>
      <div
        onClick={handleClick}
        role="presentation"
        className="position-fixed back-to-top"
      >
        <Fab
          color="inherit"
          size="small"
          aria-label="back to top"
          className="bg-dark"
        >
          <Layout.Icons.KeyboardArrowUpIcon
            height={28}
            width={28}
            className="text-white"
          />
        </Fab>
      </div>
    </Zoom>
  )
}

export default ScrollTop
