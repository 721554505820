import React from 'react'
import PropTypes from 'prop-types'
import Remove from '@mui/icons-material/Remove'

const RemoveIcon = ({ height, width, ...more }) => {
	
	return (
		<Remove
			height={height}
			width={width}
			{...more}
		/>
	)
}

RemoveIcon.propTypes = {
	height: PropTypes.number.isRequired,
	width: PropTypes.number.isRequired
}

export default RemoveIcon
