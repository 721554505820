import React from 'react'
import Layout from '../components/mui/Layout'

const SidebarRoutes = [
	{
		permission: 'main.menu',
		icon: <Layout.Icons.BarChartIcon height={20} width={20} />,
		label: 'Main',
		path: 'main',
		hasChildren: false,
		children: []
	},
	{
		permission: 'settings.menu',
		icon: <Layout.Icons.SettingsIcon height={20} width={20} />,
		label: 'Settings',
		path: null,
		hasChildren: true,
		children: [
			{
				permission: 'permissions.menu',
				icon: <Layout.Icons.RemoveIcon height={20} width={20} />,
				label: 'Permissions',
				path: 'settings/permissions',
				hasChildren: false,
				children: []
			},
			{
				permission: 'roles.menu',
				icon: <Layout.Icons.RemoveIcon height={20} width={20} />,
				label: 'Roles',
				path: 'settings/roles',
				hasChildren: false,
				children: []
			},
			{
				permission: 'companies.menu',
				icon: <Layout.Icons.RemoveIcon height={20} width={20} />,
				label: 'Companies',
				path: 'settings/companies',
				hasChildren: false,
				children: []
			},
			{
				permission: 'users.menu',
				icon: <Layout.Icons.RemoveIcon height={20} width={20} />,
				label: 'Users',
				path: 'settings/users',
				hasChildren: false,
				children: []
			},
		],
	},
]

export default SidebarRoutes

