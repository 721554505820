import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Layout from '../Layout'

const Modal = ({ maxWidth, isOpen, isClose, title, children, hasAction = false, contentActions = null, ...more }) => {
	
	return (
		<Dialog
			disableEscapeKeyDown
			fullWidth={true}
			maxWidth={maxWidth}
			open={isOpen}
			{...more}
		>
			<DialogTitle
				className="pl-3 pr-3 pt-3 pb-2"
			>
				{title}
				<Layout.IconButton
					color="inherit"
					onClick={isClose}
					className="float-end"
				>
					<Layout.Icons.CloseIcon
						height={20}
						width={20}
						className="text-dark"
					/>
				</Layout.IconButton>
			</DialogTitle>
			<DialogContent
				dividers
				{...more}
			>
				{children}
			</DialogContent>
			{hasAction && (
				<DialogActions>
					{contentActions}
				</DialogActions>
			)}
		</Dialog>
	)
}

Modal.propTypes = {
	maxWidth: PropTypes.string.isRequired,
	isOpen: PropTypes.bool.isRequired,
	isClose: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	children: PropTypes.any.isRequired,
	hasAction: PropTypes.bool,
	contentActions: PropTypes.any
}

export default Modal
