import React from 'react'
import Layout from '../../../../components/mui/Layout'
import TableComponent from './components/TableComponent'
import ModalComponent from './components/ModalComponent'

const CompanyPage = () => {
	
	return (
		<Layout.Page
			title="Compañías"
		>
			<Layout.HeaderContent
				title="Módulo de Compañías"
				subtitle="Listado de Compañías"
			/>
			
			<Layout.Card className="border-radius-6 mt-3">
				<Layout.CardContent className="m-0 p-4">
					<TableComponent />
				</Layout.CardContent>
			</Layout.Card>
			
			<ModalComponent />
		</Layout.Page>
	)
}

export default CompanyPage
