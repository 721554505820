import {history} from '../../store'
import * as types from '../../actionsTypes/auth/passwordResetTypes'
import { setIsLoading } from '../loadingAction'
import { getPasswordVerification, setPasswordResendVerification, setPasswordReset } from './services/authServices'
import { swalSuccess } from '../../../services/sweetalert'
import errorsServices from '../../../services/errors'

export const verification = param => (
	async (dispatch) => {
		await dispatch( setIsLoading(true) )
		
		await getPasswordVerification(param).then(response => {
			dispatch( setColorStatus('') )
			dispatch( setTextStatus('') )
		  dispatch( setIsActiveAlert(false) )
			dispatch( setIsActiveButton(false) )
		  dispatch( setIsActiveForm(true) )
		}).catch(({ response }) => {
			dispatch( response.data.errors[0] === 'El usuario no se encuentra registrado' ? setIsActiveButton(false) : setIsActiveButton(true) )
			dispatch( setColorStatus('danger') )
			dispatch( setTextStatus(response.data.errors[0]) )
		  dispatch( setIsActiveAlert(true) )
		  dispatch( setIsActiveForm(false) )
		})
		
		await dispatch( setIsLoading(false) )
	}
)

export const resendVerification = param => (
	async (dispatch) => {
		await dispatch( setIsLoadingForm(true) )
		
		await setPasswordResendVerification(param).then(({ data }) => {
			swalSuccess('Solicitud exitosa', data.data)
			dispatch( setIsActiveButton(false) )
		}).catch(error => {
			errorsServices(error)
			dispatch( setIsActiveButton(true) )
		})
		
		await dispatch( setIsActiveForm(false) )
		await dispatch( setIsLoadingForm(false) )
	}
)

export const passwordReset = param => (
	async (dispatch) => {
		await dispatch( setIsLoadingForm(true) )
		
		await setPasswordReset(param).then(({ data }) => {
			swalSuccess('Cambio de contraseña exitoso', data.data)
			dispatch( setIsActiveButton(false) )
			dispatch( setIsActiveAlert() )
			dispatch( setColorStatus('') )
			dispatch( setTextStatus('') )
			history.push('/')
		}).catch(error => errorsServices(error))
		
		await dispatch( setIsLoadingForm(false) )
	}
)

export const setColorStatus = value => ({
	type: types.COLOR_STATUS_PASSWORD_RESET,
	payload: value
})

export const setTextStatus = value => ({
	type: types.TEXT_STATUS_PASSWORD_RESET,
	payload: value
})

export const setIsActiveAlert = value => ({
	type: types.IS_ACTIVE_ALERT_PASSWORD_RESET,
	payload: value
})

export const setIsActiveButton = value => ({
	type: types.IS_ACTIVE_BUTTON_PASSWORD_RESET,
	payload: value
})

export const setIsActiveForm = value => ({
	type: types.IS_ACTIVE_FORM_PASSWORD_RESET,
	payload: value
})

export const setIsLoadingForm = value => ({
	type: types.IS_LOADING_FORM_PASSWORD_RESET,
	payload: value
})

