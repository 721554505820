import * as types from '../../actionsTypes/auth/verificationTypes'
import { setIsLoading } from '../loadingAction'
import { getVerification, setResendVerification } from './services/authServices'
import { swalSuccess } from '../../../services/sweetalert'
import errorsServices from '../../../services/errors'

export const verification = param => (
	async (dispatch) => {
		await dispatch( setIsLoading(true) )
		
		await getVerification(param).then(({ data }) => {
			dispatch( setIsActiveButton(false) )
			dispatch( setColorStatus('success') )
			dispatch( setTextStatus(data.data) )
		}).catch(({ response }) => {
			dispatch( response.data.errors[0] === 'El usuario se encuentra verificado' || response.data.errors[0] === 'El usuario no se encuentra registrado' ? setIsActiveButton(false) : setIsActiveButton(true) )
			dispatch( setColorStatus('danger') )
			dispatch( setTextStatus(response.data.errors[0]) )
		})
		
		await dispatch( setIsActiveAlert(true) )
		await dispatch( setIsLoading(false) )
	}
)

export const resendVerification = param => (
	async (dispatch) => {
		await dispatch( setIsLoadingForm(true) )
		
		await setResendVerification(param).then(({ data }) => {
			swalSuccess('Solicitud exitosa', data.data)
			dispatch( setIsActiveButton(false) )
		}).catch(error => {
			errorsServices(error)
			dispatch( setIsActiveButton(true) )
		})
		
		await dispatch( setIsLoadingForm(false) )
	}
)

export const setColorStatus = value => ({
	type: types.COLOR_STATUS_VERIFICATION,
	payload: value
})

export const setTextStatus = value => ({
	type: types.TEXT_STATUS_VERIFICATION,
	payload: value
})

export const setIsActiveAlert = value => ({
	type: types.IS_ACTIVE_ALERT_VERIFICATION,
	payload: value
})

export const setIsActiveButton = value => ({
	type: types.IS_ACTIVE_BUTTON_VERIFICATION,
	payload: value
})

export const setIsLoadingForm = value => ({
	type: types.IS_LOADING_FORM_VERIFICATION,
	payload: value
})
