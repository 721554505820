import React from 'react'
import Layout from '../../../components/mui/Layout'
import Logo from '../../../components/mui/Logo'
import '../NoMatchPage.scss'

const UnAuthorizationPage = () => {
	
	return (
		<Layout.Page
			title="Acceso no autorizado"
		>
			<Layout.Card className="form-container border-radius-6 mt-5">
				<Layout.CardContent className="m-0 p-4">
					<div className="row">
						<div className="col-sm-12">
							<Logo
								className="d-block mx-auto logo"
							/>
						</div>
						<div className="col-sm-12 m-2">
							<h1 className="font-family-roboto-medium fs-1 text-danger mx-auto text-center">
								Usted no tiene permiso para esta solicitud
							</h1>
							<h6 className="font-family-roboto-regular fs-5 text-gray mx-auto text-center">
								Lo sentimos pero no pudimos encontrar la página que buscas
							</h6>
						</div>
					</div>
				</Layout.CardContent>
				<Layout.CardActions
					to="/dashboard/main"
					label="Volver al escritorio"
				/>
			</Layout.Card>
		</Layout.Page>
	)
}

export default UnAuthorizationPage
