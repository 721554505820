import React from 'react'
import PropTypes from 'prop-types'

const TableRowNoFound = ({ colSpan }) => {
	return (
		<tr>
			<td colSpan={colSpan}>
				<div className="font-family-roboto-regular fs-6 text-center">No hay datos para esta consulta</div>
			</td>
		</tr>
	)
}

TableRowNoFound.propTypes = {
	colSpan: PropTypes.number
}

export default TableRowNoFound
