import ReactJwtDecode from 'jwt-decode'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

const ShowPermission = ({ permission, userPermissions, children }) => {
	if (userPermissions !== null) {
		const canShowPermission = userPermissions.includes(permission)

		return canShowPermission ? children : ''
	}

	return ''
}

ShowPermission.propTypes = {
	permission: PropTypes.string.isRequired,
	children: PropTypes.any.isRequired,
	userPermissions: PropTypes.any
}

const mapStateToProps = state => {
	let decode = ''
	const accessToken = state.auth.accessToken

	if (accessToken !== '') {
		decode = ReactJwtDecode(accessToken)
	}

	return {
		userPermissions: accessToken === '' ? null : decode.permissions
	}
}

export const Permission = connect(mapStateToProps)(ShowPermission)
