import * as types from '../../actionsTypes/auth/passwordResetTypes'

const initialState = {
	colorStatus: '',
	textStatus: '',
	isActiveAlert: false,
	isActiveButton: false,
	isActiveForm: false,
	isLoadingForm: false
}

const verificationReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.COLOR_STATUS_PASSWORD_RESET:
			return {
				...state,
				colorStatus: payload
			}
		
		case types.TEXT_STATUS_PASSWORD_RESET:
			return {
				...state,
				textStatus: payload
			}
		
		case types.IS_ACTIVE_ALERT_PASSWORD_RESET:
			return {
				...state,
				isActiveAlert: payload
			}
			
		case types.IS_ACTIVE_BUTTON_PASSWORD_RESET:
			return {
				...state,
				isActiveButton: payload
			}
			
		case types.IS_ACTIVE_FORM_PASSWORD_RESET:
			return {
				...state,
				isActiveForm: payload
			}
		
		case types.IS_LOADING_FORM_PASSWORD_RESET:
			return {
				...state,
				isLoadingForm: payload
			}
		
		default:
			return state
	}
}

export default verificationReducer
