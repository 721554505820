import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import Layout from '../../../components/mui/Layout'
import Logo from '../../../components/mui/Logo'
import '../NoMatchPage.scss'

const ErrorPage = () => {
  const isAuthenticated = useSelector( state  => state.auth.isAuthenticated)
  
  useEffect(() => {
  }, [isAuthenticated])
  
  return (
    <Layout.Page
      title="Error 404"
    >
      <Layout.Card className="form-container border-radius-6 mt-5">
        <Layout.CardContent className="m-0 p-4">
          <div className="row">
            <div className="col-sm-12">
              <Logo
                className="d-block mx-auto logo"
              />
            </div>
            <div className="col-sm-12 m-2">
              <h1 className="font-family-roboto-medium fs-1 text-danger text-center">
                404
              </h1>
              <h6 className="font-family-roboto-regular fs-5 text-gray text-center">
                Lo sentimos pero no pudimos encontrar la página que buscas
              </h6>
            </div>
          </div>
        </Layout.CardContent>
        <Layout.CardActions
          to={isAuthenticated ? '/dashboard/main' : '/'}
          label={isAuthenticated ? 'Volver al escritorio' : 'Volver al inicio de sesión'}
        />
      </Layout.Card>
    </Layout.Page>
  )
}

export default ErrorPage
