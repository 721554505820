export const PER_PAGE_COMPANY = 'PER_PAGE_COMPANY'
export const PAGE_COMPANY = 'PAGE_COMPANY'
export const SEARCH_COMPANY = 'SEARCH_COMPANY'
export const PAGES_COMPANY = 'PAGES_COMPANY'
export const ARRAY_LIST_COMPANY = 'ARRAY_LIST_COMPANY'
export const ARRAY_LIST_TO_DROPDOWN_COMPANY = 'ARRAY_LIST_TO_DROPDOWN_COMPANY'
export const IS_OPEN_MODAL_COMPANY = 'IS_OPEN_MODAL_COMPANY'
export const IS_LOADING_TABLE_COMPANY = 'IS_LOADING_TABLE_COMPANY'
export const IS_LOADING_FORM_COMPANY = 'IS_LOADING_FORM_COMPANY'
export const FORM_TYPE_COMPANY = 'FORM_TYPE_COMPANY'
export const FORM_DATA_COMPANY = 'FORM_DATA_COMPANY'
