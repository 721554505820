import endPoints from './authEndpoints'
import axios from '../../../../services/axios'
import {createSignIn} from "../authAction";

const {
	signIn,
	verification,
	resendVerification,
	forgotPassword,
	passwordVerification,
	passwordResendVerification,
	passwordReset,
	signOut
} = endPoints

export const setSignIn = async (data) => (
  await axios.post(signIn, data)
)

export const getVerification = async (data) => (
  await axios.post(verification, data)
)

export const setResendVerification = async (data) => (
  await axios.post(resendVerification, data)
)

export const setForgotPassword = async (data) => (
  await axios.post(forgotPassword, data)
)

export const getPasswordVerification = async (data) => (
  await axios.post(passwordVerification, data)
)

export const setPasswordResendVerification = async (data) => (
  await axios.post(passwordResendVerification, data)
)

export const setPasswordReset = async (data) => (
  await axios.post(passwordReset, data)
)

export const setSignOut = async () => (
  await axios.get(signOut)
)
