import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import Inputs from '../../../../../components/mui/Inputs'
import Layout from '../../../../../components/mui/Layout'
import { getRoleArrayToDropdown } from '../../../../../redux/actions/dashboard/settings/roles/roleAction'
import { getCompanyStateArrayToDropdown } from '../../../../../redux/actions/dashboard/settings/companies/companyAction'
import { setFormData, setUserStore, setUserUpdateById } from '../../../../../redux/actions/dashboard/settings/users/userAction'
import { Permission } from '../../../../../services/permissions/Permission'
import { initialValuesUser as initialValues } from '../../../../../helpers/variablesInitialValues'

const FormComponent = () => {
	const dispatch = useDispatch()
	const isLoadingForm = useSelector( state => state.user.isLoadingForm)
	const isLoadingTable = useSelector( state => state.user.isLoadingTable)
	const formType = useSelector( state => state.user.formType)
	const formData = useSelector( state => state.user.formData)
	const arrayListToDropdownRole = useSelector( state => state.role.arrayListToDropdown)
	const arrayListToDropdownRealState = useSelector( state => state.realState.arrayListToDropdown)
	
	const getArrayToDropdownRole = async () => await dispatch( getRoleArrayToDropdown() )
	
	useEffect(() => {
		getArrayToDropdownRole()
	}, [])
	
	const getArrayToDropdownCompany = async () => await dispatch( getCompanyStateArrayToDropdown() )
	
	useEffect(() => {
		getArrayToDropdownCompany()
	}, [])
	
	const validationSchema = () => {
		if (formType === 'store') {
			return Yup.object({
				firstName: Yup.string()
				.required('El nombre es requerido'),
				lastName: Yup.string()
				.required('El apellido es requerido'),
				email: Yup.string()
				.email('El email no es válido')
				.required('El email es requerido'),
				mobile: Yup.string()
				.required('El celular es requerido'),
				password: Yup.string()
				.min(6, 'La contraseña debe contener al menos 6 caracteres')
				.required('La contraseña es requerida'),
				passwordConfirm: Yup.string()
				.min(6, 'La confirmación de la contraseña debe contener al menos 6 caracteres')
				.required('La confirmación de la contraseña es requerida')
				.oneOf([Yup.ref('password'), null], 'La confirmación debe ser igual a la contraseña'),
				role: Yup.string()
				.required('El rol es requerido')
			})
		} else {
			return Yup.object({
				firstName: Yup.string()
				.required('El nombre es requerido'),
				lastName: Yup.string()
				.required('El apellido es requerido'),
				email: Yup.string()
				.email('El email no es válido')
				.required('El email es requerido'),
				mobile: Yup.string()
				.required('El celular es requerido'),
				role: Yup.string()
				.required('El rol es requerido')
			})
		}
	}
	
	const hanbleSubmit = async (values, { setSubmitting, resetForm, setValues }) => {
		setSubmitting(false)
		
		const param = {
			first_name: formData.firstName,
			last_name: formData.lastName,
			email: formData.email,
			mobile: formData.mobile,
			phone: formData.phone,
			address: formData.address,
			realstate: formData.realState,
			role: formData.role
		}
		
		if (formType === 'store') {
			await dispatch( setUserStore(param) )
		} else if (formType === 'show') {
			await dispatch( setUserUpdateById(formData.id, param) )
		}
		
		isLoadingTable && resetForm({ values: initialValues})
		isLoadingTable && setValues(initialValues)
	}
	
	const handleOnChange = ({ target }) => dispatch( setFormData( { ...formData, [target.name]: target.value }) )
	
	return (
		<Formik
			initialValues={formData}
			validationSchema={validationSchema}
			onSubmit={hanbleSubmit}
		>
			{({
				  values,
				  errors,
				  handleChange,
				  handleBlur,
				  isValid,
				  dirty
			  }) => (
				<Form noValidate>
					<div className="row">
						<div className="col-sm-12 col-md-6">
							<Inputs.TextBox
								type="text"
								variant="outlined"
								size="small"
								name="firstName"
								label="Nombre"
								placeholder="Ingrese nombre..."
								hasLabelFixed={true}
								value={formData.firstName}
								onChange={e => {
									handleOnChange(e)
									handleChange(e)
								}}
								onBlur={handleBlur}
								error={errors.firstName ? true : false}
								helperText={errors.firstName ? errors.firstName : ''}
								className="font-family-roboto-medium text-dark fs-5 w-100 mt-3"
							/>
						</div>
						<div className="col-sm-12 col-md-6">
							<Inputs.TextBox
								type="text"
								variant="outlined"
								size="small"
								name="lastName"
								label="Apellido"
								placeholder="Ingrese apellido..."
								hasLabelFixed={true}
								value={formData.lastName}
								onChange={e => {
									handleOnChange(e)
									handleChange(e)
								}}
								onBlur={handleBlur}
								error={errors.lastName ? true : false}
								helperText={errors.lastName ? errors.lastName : ''}
								className="font-family-roboto-medium text-dark fs-5 w-100 mt-3"
							/>
						</div>
						<div className="col-sm-12 col-md-6">
							<Inputs.TextBox
								type="email"
								variant="outlined"
								size="small"
								name="email"
								label="Email"
								placeholder="Ingrese email..."
								hasLabelFixed={true}
								value={formData.email}
								onChange={e => {
									handleOnChange(e)
									handleChange(e)
								}}
								onBlur={handleBlur}
								error={errors.email ? true : false}
								helperText={errors.email ? errors.email : ''}
								className="font-family-roboto-medium text-dark fs-5 w-100 mt-3"
							/>
						</div>
						<div className="col-sm-12 col-md-6">
							<Inputs.TextBox
								type="number"
								variant="outlined"
								size="small"
								name="mobile"
								label="Celular"
								placeholder="Ingrese celular..."
								hasLabelFixed={true}
								value={formData.mobile}
								onChange={e => {
									handleOnChange(e)
									handleChange(e)
								}}
								onBlur={handleBlur}
								error={errors.mobile ? true : false}
								helperText={errors.mobile ? errors.mobile : ''}
								className="font-family-roboto-medium text-dark fs-5 w-100 mt-3"
							/>
						</div>
						{formType === 'store' && (
							<>
								<div className="col-sm-12 col-md-6">
									<Inputs.TextBoxPassword
										variant="outlined"
										size="small"
										name="password"
										label="Contraseña"
										placeholder="Ingrese contraseña..."
										hasLabelFixed={true}
										value={formData.password}
										onChange={e => {
											handleOnChange(e)
											handleChange(e)
										}}
										onBlur={handleBlur}
										error={errors.password ? true : false}
										helperText={errors.password ? errors.password : ''}
										className="font-family-roboto-medium text-dark fs-5 mt-3 w-100"
									/>
								</div>
								<div className="col-sm-12 col-md-6">
									<Inputs.TextBoxPassword
										variant="outlined"
										size="small"
										name="passwordConfirm"
										label="Confirmar contraseña"
										placeholder="Ingrese confirmación..."
										hasLabelFixed={true}
										value={formData.passwordConfirm}
										onChange={e => {
											handleOnChange(e)
											handleChange(e)
										}}
										onBlur={handleBlur}
										error={errors.passwordConfirm ? true : false}
										helperText={errors.passwordConfirm ? errors.passwordConfirm : ''}
										className="font-family-roboto-medium text-dark fs-5 mt-3 w-100"
									/>
								</div>
							</>
						)}
						
						<div className="col-sm-12 col-md-6">
							<Inputs.TextBox
								type="number"
								variant="outlined"
								size="small"
								name="phone"
								label="Teléfono"
								placeholder="Ingrese teléfono..."
								hasLabelFixed={true}
								value={formData.phone}
								onChange={e => {
									handleOnChange(e)
									handleChange(e)
								}}
								onBlur={handleBlur}
								className="font-family-roboto-medium text-dark fs-5 w-100 mt-3"
							/>
						</div>
						
						<div className="col-sm-12 col-md-6">
							<Inputs.TextArea
								variant="outlined"
								size="small"
								name="address"
								label="Dirección"
								placeholder="Ingrese dirección..."
								hasLabelFixed={true}
								value={formData.address}
								onChange={e => {
									handleOnChange(e)
									handleChange(e)
								}}
								onBlur={handleBlur}
								className="font-family-roboto-medium text-dark fs-5 w-100 mt-3"
							/>
						</div>
						
						<Permission
							permission="roles.dropdown"
						>
							<div className="col-sm-12 col-md-6">
								<Inputs.Select
									classes="mt-3 w-100"
									size="small"
									label="Roles"
									name="role"
									value={formData.role}
									itemsArray={arrayListToDropdownRole}
									onChange={e => {
										handleOnChange(e)
										handleChange(e)
									}}
									error={errors.role ? true : false}
									helperText={errors.role ? errors.role : ''}
									className="font-family-roboto-regular text-dark fs-6"
								/>
							</div>
						</Permission>
						
						<Permission
							permission="realstates.dropdown"
						>
							<div className="col-sm-12 col-md-6">
								<Inputs.Select
									classes="mt-3 w-100"
									size="small"
									label="Inmobiliarias"
									name="realState"
									value={formData.realState}
									itemsArray={arrayListToDropdownRealState}
									onChange={e => {
										handleOnChange(e)
										handleChange(e)
									}}
									className="font-family-roboto-regular text-dark fs-6"
								/>
							</div>
						</Permission>
					
						<Permission
							permission={`${formType === 'store' ? 'users.store' : 'users.update'}`}
						>
							<div className="col-sm-12">
								<Inputs.LoadingButton
									isDisabled={formType === 'store' ? !(dirty && isValid) : false}
									variant="contained"
									color="primary"
									isLoading={isLoadingForm}
									isLoadingPosition="start"
									startIcon={formType === 'store' ? <Layout.Icons.SaveIcon height={18} width={18} /> : <Layout.Icons.EditIcon height={18} width={18} />}
									label={`${formType === 'store' ? 'Guardar' : 'Actualizar'}`}
									type="submit"
									className="font-family-roboto-regular fs-6 mt-2 d-flex float-end"
								/>
							</div>
						</Permission>
					</div>
				</Form>
			)}
		</Formik>
	)
}

export default FormComponent
