import endPoints from './userEndpoints'
import axios from '../../../../../../services/axios'

const {
	arrayList,
	arrayListToDropdown,
	store,
	show,
	update,
	assignPermissions,
	changeProfile,
	changeProfilePassword,
	destroy
} = endPoints

export const getArrayList = async (data) => (
	await axios.post(arrayList, data)
)

export const getArrayListToDropdown = async () => (
	await axios.get(`${arrayListToDropdown}`)
)

export const setStore = async (data) => (
	await axios.post(store, data)
)

export const getShowById = async (id) => (
	await axios.get(`${show}${id}`)
)

export const setUpdateById = async (id, data) => (
	await axios.put(`${update}${id}`, data)
)

export const setAssignPermissionsById = async (id, data) => (
	await axios.put(`${assignPermissions}${id}`, data)
)

export const setChangeProfileById = async (data) => (
	await axios.post(changeProfile, data)
)

export const setChangeProfilePasswordById = async (data) => (
	await axios.post(changeProfilePassword, data)
)

export const setDeleteById = async (id) => (
	await axios.delete(`${destroy}${id}`)
)


