import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import Inputs from '../../../../../components/mui/Inputs'
import Layout from '../../../../../components/mui/Layout'
import { changeProfilePasswordById, setFormData } from '../../../../../redux/actions/dashboard/settings/users/profileAction'
import { initialValuesProfile as initialValues } from '../../../../../helpers/variablesInitialValues'

const FormPasswordReset = () => {
	const dispatch = useDispatch()
	const isLoading = useSelector( state => state.profile.isLoading)
	const formData = useSelector( state => state.profile.formData)
	
	const validationSchema = () => (
		Yup.object({
			password: Yup.string()
			.min(6, 'La contraseña debe contener al menos 6 caracteres')
			.required('La contraseña es requerida'),
			passwordConfirm: Yup.string()
			.min(6, 'La confirmación de la contraseña debe contener al menos 6 caracteres')
			.required('La confirmación de la contraseña es requerida')
			.oneOf([Yup.ref('password'), null], 'La confirmación debe ser igual a la contraseña')
		})
	)
	
	const hanbleSubmit = async (values, { setSubmitting, resetForm, setValues }) => {
		setSubmitting(false)
		
		const param = {
			password: formData.password,
			password_confirm: formData.passwordConfirm,
		}
		
		await dispatch( changeProfilePasswordById(param) )
		
		resetForm({ values: initialValues})
		setValues(initialValues)
	}
	
	const handleOnChange = ({ target }) => dispatch( setFormData( { ...formData, [target.name]: target.value }) )
	
	return (
		<Formik
			initialValues={formData}
			validationSchema={validationSchema}
			onSubmit={hanbleSubmit}
		>
			{({
				  values,
				  errors,
				  handleChange,
				  handleBlur,
				  isValid,
				  dirty
			  }) => (
				<Form noValidate>
					<div className="row">
						<div className="col-sm-12 col-md-6">
							<Inputs.TextBoxPassword
								variant="outlined"
								size="small"
								name="password"
								label="Contraseña"
								placeholder="Ingrese contraseña..."
								hasLabelFixed={true}
								value={formData.password}
								onChange={e => {
									handleOnChange(e)
									handleChange(e)
								}}
								onBlur={handleBlur}
								hasError={errors.password ? true : false}
								errorMessage={errors.password ? errors.password : ''}
								className="font-family-roboto-medium text-dark fs-5 mt-3 w-100"
							/>
						</div>
						<div className="col-sm-12 col-md-6">
							<Inputs.TextBoxPassword
								variant="outlined"
								size="small"
								name="passwordConfirm"
								label="Confirmar contraseña"
								placeholder="Ingrese confirmación..."
								hasLabelFixed={true}
								value={formData.passwordConfirm}
								onChange={e => {
									handleOnChange(e)
									handleChange(e)
								}}
								onBlur={handleBlur}
								hasError={errors.passwordConfirm ? true : false}
								errorMessage={errors.passwordConfirm ? errors.passwordConfirm : ''}
								className="font-family-roboto-medium text-dark fs-5 mt-3 w-100"
							/>
						</div>
						
						<div className="col-sm-12">
							<Inputs.LoadingButton
								isDisabled={!(dirty && isValid)}
								variant="contained"
								color="primary"
								isLoading={isLoading}
								isLoadingPosition="start"
								startIcon={<Layout.Icons.LockOpenIcon height={18} width={18} />}
								label="Resetear contraseña"
								type="submit"
								className="font-family-roboto-regular fs-6 mt-2 d-flex float-end"
							/>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	)
}

export default FormPasswordReset
