import React from 'react'
import AuthRoutes from '../routes/AuthRoutes'

const PublicRouter = () => {
	
	return (
		<AuthRoutes />
	)
}

export default PublicRouter
