import React from 'react'
import { useRoutes } from 'react-router-dom'
import LoginPage from '../pages/auth/LoginPage'
import VerificationPage from '../pages/auth/VerificationPage'
import ForgotPasswordPage from '../pages/auth/ForgotPasswordPage'
import PasswordResetPage from '../pages/auth/PasswordResetPage'
// import CallCenterPage from '../pages/filters/CallCenterPage'
// import InventoryPage from '../pages/owners/InventoryPage'
// import AuthorizationPage from '../pages/owners/AuthorizationPage'
import ErrorPage from '../pages/errors/ErrorPage'

const AuthRoutes = () => {
	return useRoutes([
		{
			index: true,
			path: '',
			element: <LoginPage />
		},
		{
			index: false,
			path: 'verification/:uID',
			element: <VerificationPage />
		},
		{
			index: false,
			path: 'forgot-password',
			element: <ForgotPasswordPage />
		},
		{
			index: false,
			path: 'password-reset/:uID',
			element: <PasswordResetPage />
		},
		// {
		// 	index: false,
		// 	path: 'filter/call-center/:uID',
		// 	element: <CallCenterPage />
		// },
		// {
		// 	index: false,
		// 	path: 'owners/inventory/:propertyID/:albumID',
		// 	element: <InventoryPage />
		// },
		// {
		// 	index: false,
		// 	path: 'owners/authorization/:propertyAuthorizationID',
		// 	element: <AuthorizationPage />
		// },
		{
			index: false,
			path: '*',
			element: <ErrorPage />
		}
	])
}

export default AuthRoutes

