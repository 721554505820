import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'

const TextArea = (props) => {
	const { variant,
		size = 'medium',
		name,
		label,
		placeholder,
		hasLabelFixed,
		value,
		onChange = null,
		onBlur = null,
		onKeyPress = null,
		hasError = false,
		errorMessage = '',
		...more
	} = props
	
	return (
		<>
			<TextField
				fullWidth
				multiline
				maxRows={4}
				variant={variant}
				size={size}
				name={name}
				label={label}
				placeholder={placeholder}
				InputLabelProps={{
					shrink: hasLabelFixed,
				}}
				defaultValue={value}
				onChange={onChange}
				onBlur={onBlur}
				onKeyPress={onKeyPress}
				error={hasError}
				{...more}
			/>
			{hasError && errorMessage !== "" && (
				<div className="mt-1 bg-grey-light border-l-4 border-danger p-2 font-family-roboto-regular fs-6 text-danger animate__animated animate__fadeIn">
					{errorMessage}
				</div>
			)}
		</>
	)
}

TextArea.propTypes = {
	variant: PropTypes.string.isRequired,
	size: PropTypes.string,
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	placeholder: PropTypes.string.isRequired,
	hasLabelFixed: PropTypes.bool.isRequired,
	value: PropTypes.any.isRequired,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	onKeyPress: PropTypes.func,
	hasError: PropTypes.bool,
	errorMessage: PropTypes.string
}

export default TextArea
