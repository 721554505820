import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import Inputs from '../../../../../components/mui/Inputs'
import Layout from '../../../../../components/mui/Layout'
import { changeProfileById, setFormData } from '../../../../../redux/actions/dashboard/settings/users/profileAction'
import { initialValuesProfile as initialValues } from '../../../../../helpers/variablesInitialValues'

const FormProfileComponent = () => {
	const dispatch = useDispatch()
	const isLoading = useSelector( state => state.profile.isLoading)
	const formData = useSelector( state => state.profile.formData)
	
	const validationSchema = () => (
		Yup.object({
			firstName: Yup.string()
			.required('El nombre es requerido'),
			lastName: Yup.string()
			.required('El apellido es requerido'),
			email: Yup.string()
			.email('El email no es válido')
			.required('El email es requerido'),
			mobile: Yup.string()
			.required('El celular es requerido')
		})
	)
	
	const hanbleSubmit = async (values, { setSubmitting, resetForm, setValues }) => {
		setSubmitting(false)
		
		const param = {
			first_name: formData.firstName,
			last_name: formData.lastName,
			email: formData.email,
			mobile: formData.mobile,
			phone: formData.phone,
			address: formData.address
		}
		
		await dispatch( changeProfileById(param) )
		
		resetForm({ values: initialValues})
		setValues(initialValues)
	}
	
	const handleOnChange = ({ target }) => dispatch( setFormData( { ...formData, [target.name]: target.value }) )
	
	return (
		<Formik
			initialValues={formData}
			validationSchema={validationSchema}
			onSubmit={hanbleSubmit}
		>
			{({
				  values,
				  errors,
				  handleChange,
				  handleBlur,
				  isValid,
				  dirty
			  }) => (
				<Form noValidate>
					<div className="row">
						<div className="col-sm-12 col-md-6">
							<Inputs.TextBox
								type="text"
								variant="outlined"
								size="small"
								name="firstName"
								label="Nombre"
								placeholder="Ingrese nombre..."
								hasLabelFixed={true}
								value={formData.firstName}
								onChange={e => {
									handleOnChange(e)
									handleChange(e)
								}}
								onBlur={handleBlur}
								hasError={errors.firstName ? true : false}
								errorMessage={errors.firstName ? errors.firstName : ''}
								className="font-family-roboto-medium text-dark fs-5 w-100 mt-3"
							/>
						</div>
						<div className="col-sm-12 col-md-6">
							<Inputs.TextBox
								type="text"
								variant="outlined"
								size="small"
								name="lastName"
								label="Apellido"
								placeholder="Ingrese apellido..."
								hasLabelFixed={true}
								value={formData.lastName}
								onChange={e => {
									handleOnChange(e)
									handleChange(e)
								}}
								onBlur={handleBlur}
								hasError={errors.lastName ? true : false}
								errorMessage={errors.lastName ? errors.lastName : ''}
								className="font-family-roboto-medium text-dark fs-5 w-100 mt-3"
							/>
						</div>
						<div className="col-sm-12 col-md-6">
							<Inputs.TextBox
								type="email"
								variant="outlined"
								size="small"
								name="email"
								label="Email"
								placeholder="Ingrese email..."
								hasLabelFixed={true}
								value={formData.email}
								onChange={e => {
									handleOnChange(e)
									handleChange(e)
								}}
								onBlur={handleBlur}
								hasError={errors.email ? true : false}
								errorMessage={errors.email ? errors.email : ''}
								className="font-family-roboto-medium text-dark fs-5 w-100 mt-3"
							/>
						</div>
						<div className="col-sm-12 col-md-6">
							<Inputs.TextBox
								type="number"
								variant="outlined"
								size="small"
								name="mobile"
								label="Celular"
								placeholder="Ingrese celular..."
								hasLabelFixed={true}
								value={formData.mobile}
								onChange={e => {
									handleOnChange(e)
									handleChange(e)
								}}
								onBlur={handleBlur}
								hasError={errors.mobile ? true : false}
								errorMessage={errors.mobile ? errors.mobile : ''}
								className="font-family-roboto-medium text-dark fs-5 w-100 mt-3"
							/>
						</div>
						
						<div className="col-sm-12 col-md-6">
							<Inputs.TextBox
								type="number"
								variant="outlined"
								size="small"
								name="phone"
								label="Teléfono"
								placeholder="Ingrese teléfono..."
								hasLabelFixed={true}
								value={formData.phone}
								onChange={e => {
									handleOnChange(e)
									handleChange(e)
								}}
								onBlur={handleBlur}
								className="font-family-roboto-medium text-dark fs-5 w-100 mt-3"
							/>
						</div>
						
						<div className="col-sm-12 col-md-6">
							<Inputs.TextArea
								variant="outlined"
								size="small"
								name="address"
								label="Dirección"
								placeholder="Ingrese dirección..."
								hasLabelFixed={true}
								value={formData.address}
								onChange={e => {
									handleOnChange(e)
									handleChange(e)
								}}
								onBlur={handleBlur}
								className="font-family-roboto-medium text-dark fs-5 w-100 mt-3"
							/>
						</div>
						
						<div className="col-sm-12">
							<Inputs.LoadingButton
								isDisabled={!(dirty && isValid)}
								variant="contained"
								color="primary"
								isLoading={isLoading}
								isLoadingPosition="start"
								startIcon={<Layout.Icons.SaveIcon height={18} width={18} />}
								label="Guardar"
								type="submit"
								className="font-family-roboto-regular fs-6 mt-2 d-flex float-end"
							/>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	)
}

export default FormProfileComponent
