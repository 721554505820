import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import Feedback from '../../../../../components/mui/Feedback'
import Layout from '../../../../../components/mui/Layout'
import Inputs from '../../../../../components/mui/Inputs'
import { setCompanyStateDeleteById } from '../../../../../redux/actions/dashboard/settings/companies/companyAction'

const DeleteComponent = ({ id, name, isOpen, setIsOpen }) => {
	const dispatch = useDispatch()
	
	useEffect(() => {
	}, [id, name, isOpen])
	
	const handleDeleteById = () => {
		dispatch( setCompanyStateDeleteById(id) )
		handleIsCloseModal()
	}
	
	const handleIsCloseModal = () => setIsOpen(false)
	
	return (
		<Feedback.Modal
			maxWidth="sm"
			isOpen={isOpen}
			isClose={handleIsCloseModal}
			title={`Eliminación de la compañía ${name}`}
			hasAction={true}
			contentActions={
				<>
					<Inputs.Button
						variant="contained"
						color="error"
						label="Cancelar"
						startIcon={<Layout.Icons.CloseIcon width={20} height={20} />}
						onClick={handleIsCloseModal}
						className="mr-2"
					/>
					<Inputs.Button
						variant="contained"
						color="primary"
						label="Aceptar"
						startIcon={<Layout.Icons.CheckIcon width={20} height={20} />}
						onClick={handleDeleteById}
					/>
				</>
			}
		>
			<div className="row">
				<div className="col-sm-12">
					<div className="font-family-roboto-regular fs-5 text-dark-light">
						¿Estás seguro que desea eliminar la compañía {name}?
					</div>
				</div>
			</div>
		</Feedback.Modal>
	)
}

DeleteComponent.propTypes = {
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired
}

export default DeleteComponent
