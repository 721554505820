const ROOT = 'dashboard/settings/permissions/'

export default {
	arrayList: `${ROOT}list`,
	arrayListToDropdown: `${ROOT}dropdown`,
	store: `${ROOT}store`,
	show: `${ROOT}show/`,
	update: `${ROOT}update/`,
	destroy: `${ROOT}delete/`,
}
