import React from 'react'
import PropTypes from 'prop-types'
import BarChart from '@mui/icons-material/BarChart'

const BarChartIcon = ({ height, width, ...more }) => {
	
	return (
		<BarChart
			height={height}
			width={width}
			{...more}
		/>
	)
}

BarChartIcon.propTypes = {
	height: PropTypes.number.isRequired,
	width: PropTypes.number.isRequired
}

export default BarChartIcon
