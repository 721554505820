import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'
import List from '@mui/material/List'
import Logo from '../../../../components/mui/Logo'
import ListItemComponent from './components/ListItemComponent'
import { Permission } from '../../../../services/permissions/Permission'
import SidebarRoutes from '../../../../routes/SidebarRoutes'
import './Sidebar.scss'

const Sidebar = () => {
  const isLoading = useSelector( state => state.sidebar.isLoading)
  const userData = useSelector( state  => state.auth.userData)
  const { firstName, lastName, roles } = userData
  
  return (
    <>
      <div className="sidebar-header m-0 pt-3 pb-3 pl-2 pr-2">
        <div className="row">
          <div className="col-sm-12">
            <Logo
              className="d-block mx-auto logo"
            />
          </div>
          <div className="col-sm-12 mt-3 text-center">
            <div className="font-family-roboto-regular fs-5 text-dark text-wrap text-truncate">
              {`${firstName} ${lastName}`}
            </div>
            <div className="font-family-roboto-medium fs-6 text-grey text-wrap text-truncate">
              {roles[0]}
            </div>
          </div>
        </div>
      </div>
      <List
        aria-labelledby="nested-list-subheader"
        component="nav"
      >
        {isLoading && (
          <div className="justify-content-center mx-auto text-center">
            <CircularProgress
              style={{height: '32px', width: '32px'}}
              color="inherit"
            />
          </div>
        )}
        {!isLoading && (
          SidebarRoutes.length > 0 && (
            SidebarRoutes.map(({ permission, icon, label, path, hasChildren, children }, number) => (
              <Permission
                key={number}
                permission={permission}
              >
                <ListItemComponent
                  icon={icon}
                  label={label}
                  path={path}
                  hasChildren={hasChildren}
                  children={children}
                />
              </Permission>
            ))
          )
        )}
      </List>
    </>
  )
}

export default Sidebar
