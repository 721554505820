import React from 'react'
import PropTypes from 'prop-types'
import { Link as MuiLink } from 'react-router-dom'

const Link = ({ to, label, children, ...more }) => {
	
	return (
		<MuiLink
			to={to}
			{...more}
		>
			{label || children}
		</MuiLink>
	)
}

Link.propTypes = {
	to: PropTypes.string.isRequired,
	label: PropTypes.string,
	children: PropTypes.any
}

export default Link
