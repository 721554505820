import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import Inputs from '../../../../../components/mui/Inputs'
import Layout from '../../../../../components/mui/Layout'
import { setFormData, setRoleStore, setRoleUpdateById } from '../../../../../redux/actions/dashboard/settings/roles/roleAction'
import { Permission } from '../../../../../services/permissions/Permission'
import { initialValuesRole as initialValues } from '../../../../../helpers/variablesInitialValues'

const FormComponent = () => {
	const dispatch = useDispatch()
	const isLoadingForm = useSelector( state => state.role.isLoadingForm)
	const isLoadingTable = useSelector( state => state.role.isLoadingTable)
	const formType = useSelector( state => state.role.formType)
	const formData = useSelector( state => state.role.formData)

	const validationSchema = () => (
		Yup.object({
			name: Yup.string()
			.required('El nombre es requerido'),
		})
	)
	
	const hanbleSubmit = async (values, { setSubmitting, resetForm, setValues }) => {
		setSubmitting(false)
		
		if (formType === 'store') {
			await dispatch( setRoleStore(formData) )
		} else if (formType === 'show') {
			await dispatch( setRoleUpdateById(formData.id, formData) )
		}
		
		isLoadingTable && resetForm({ values: initialValues})
		isLoadingTable && setValues(initialValues)
	}
	
	const handleOnChange = ({ target }) => dispatch( setFormData( { ...formData, [target.name]: target.value }) )
	
	return (
		<Formik
			initialValues={formData}
			validationSchema={validationSchema}
			onSubmit={hanbleSubmit}
		>
			{({
				  values,
				  errors,
				  handleChange,
				  handleBlur,
				  isValid,
				  dirty
			  }) => (
				<Form noValidate>
					<Inputs.TextBox
						type="text"
						variant="outlined"
						size="small"
						name="name"
						label="Nombre"
						placeholder="Ingrese nombre..."
						hasLabelFixed={true}
						value={formData.name}
						onChange={e => {
							handleOnChange(e)
							handleChange(e)
						}}
						onBlur={handleBlur}
						error={errors.name ? true : false}
						helperText={errors.name ? errors.name : ''}
						className="font-family-roboto-medium text-dark fs-5 w-100"
					/>
					
					<Permission
						permission={`${formType === 'store' ? 'roles.store' : 'roles.update'}`}
					>
						<Inputs.LoadingButton
							isDisabled={formType === 'store' ? !(dirty && isValid) : false}
							variant="contained"
							color="primary"
							isLoading={isLoadingForm}
							isLoadingPosition="start"
							startIcon={formType === 'store' ? <Layout.Icons.SaveIcon height={18} width={18} /> : <Layout.Icons.EditIcon height={18} width={18} />}
							label={`${formType === 'store' ? 'Guardar' : 'Actualizar'}`}
							type="submit"
							className="font-family-roboto-regular fs-6 mt-2 d-flex float-end"
						/>
					</Permission>
				</Form>
			)}
		</Formik>
	)
}

export default FormComponent
