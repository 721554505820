import { history } from '../../store'
import * as types from '../../actionsTypes/auth/forgotPasswordTypes'
import { setForgotPassword } from './services/authServices'
import { swalSuccess } from '../../../services/sweetalert'
import errorServices from '../../../services/errors'

export const forgotPassword = param => (
	async (dispatch) => {
		await dispatch( setIsLoadingForm(true) )
		
		await setForgotPassword(param).then(({ data }) => {
			swalSuccess('Solicitud exitosa', data.data)
			history.push('/')
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoadingForm(false) )
	}
)

export const setIsLoadingForm = value => ({
	type: types.IS_LOADING_FORM_FORGOT_PASSWORD,
	payload: value
})
