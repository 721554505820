import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Layout from '../../../../../components/mui/Layout'
import TableRowComponent from './TableRowComponent'
import { getPermissionArray, setPerPage, setPage, setSearch, setIsLoadingTable, setIsOpenModal, setFormType, setFormData } from '../../../../../redux/actions/dashboard/settings/permissions/permissionAction'
import { Permission } from '../../../../../services/permissions/Permission'
import { permissionColumns as columns, perPages } from '../../../../../helpers/variablesColumns'
import { initialValuesPermission as initialValues } from '../../../../../helpers/variablesInitialValues'

const TableComponent = () => {
	const dispatch = useDispatch()
	const isLoading = useSelector( state => state.loading.isLoading)
	const isLoadingTable = useSelector( state => state.permission.isLoadingTable)
	const perPage = useSelector( state => state.permission.perPage)
	const page = useSelector( state => state.permission.page)
	const search = useSelector( state => state.permission.search)
	const pages = useSelector( state => state.permission.pages)
	const arrayList = useSelector( state => state.permission.arrayList)
	
	const getArrayList = async () => {
		const param = {
			perPage: perPage,
			page: page,
			search: search === '' ? null : search
		}
		
		await dispatch( getPermissionArray(param) )
	}
	
	useEffect(() => {
		isLoadingTable && getArrayList()
	}, [perPage, page, isLoadingTable])
	
	const handleChangePerPage = ({ target }) => dispatch( setPerPage(target.value) )
	
	const handleChangePage = (event, value) => dispatch( setPage(value) )
	
	const handleChangeSearch = ({ target }) => dispatch( setSearch(target.value) )
	
	const handleKeyPressArrayList = async ({ charCode }) => charCode === 13 && await getArrayList()
	
	const handleIsOpenModal = () => {
		dispatch( setFormData(initialValues) )
		dispatch( setFormType('store') )
		dispatch( setIsLoadingTable(false) )
		dispatch( setIsOpenModal(true) )
	}
	
	return (
		<>
			<Layout.TableSearch
				permissionList="permissions.list"
				permissionButton="permissions.store"
				value={search}
				handleChange={handleChangeSearch}
				handleIsOpenModal={handleIsOpenModal}
				handleKeyPressArrayList={handleKeyPressArrayList}
			/>
			<Permission
				permission="permissions.list"
			>
				<Layout.TablePagination
					arrayList={arrayList.length}
					perPage={perPage}
					perPages={perPages}
					page={page}
					pages={pages}
					handleChangePerPage={handleChangePerPage}
					handleChangePage={handleChangePage}
				/>
				<Layout.Table>
					<Layout.TableHead
						columns={columns}
					/>
					<tbody>
					{isLoading && (
						<Layout.TableRowLoading
							columns={columns}
						/>
					)}
					{!isLoading && (
						<>
							{arrayList.length === 0 && (
								<Layout.TableRowNoFound
									colSpan={columns.length}
								/>
							)}
							{arrayList.length > 0 && (
								arrayList.map(({ id, name, public_name, description, group }, number) => (
									<TableRowComponent
										key={number}
										id={id}
										name={name}
										publicName={public_name}
										description={description}
										group={group}
									/>
								))
							)}
						</>
					)}
					</tbody>
				</Layout.Table>
				<Layout.TablePagination
					arrayList={arrayList.length}
					perPage={perPage}
					perPages={perPages}
					page={page}
					pages={pages}
					handleChangePerPage={handleChangePerPage}
					handleChangePage={handleChangePage}
				/>
			</Permission>
		</>
	)
}

export default TableComponent
