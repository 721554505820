import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import loadingReducer from './loadingReducer'
import authReducer from './auth/authReducer'
import verificationReducer from './auth/verificationReducer'
import forgotPasswordReducer from './auth/forgotPasswordReducer'
import passwordResetReducer from './auth/passwordResetReducer'
import sidebarReducer from './dashboard/sidebarReducer'
import permissionReducer from './dashboard/settings/permissionReducer'
import roleReducer from './dashboard/settings/roleReducer'
import companyReducer from './dashboard/settings/companyReducer'
import userReducer from './dashboard/settings/userReducer'
import profileReducer from './dashboard/settings/profileReducer'

const createReducer = asyncReducers => (
  combineReducers({
    auth: authReducer,
    verification: verificationReducer,
    forgotPassword: forgotPasswordReducer,
    passwordReset: passwordResetReducer,
    loading: loadingReducer,
    sidebar: sidebarReducer,
    permission: permissionReducer,
    role: roleReducer,
    company: companyReducer,
    user: userReducer,
    profile: profileReducer,
    router: routerReducer,
    ...asyncReducers
  })
)

export default createReducer
