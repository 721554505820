import React from 'react'
import PropTypes from 'prop-types'
import MuiChip from '@mui/material/Chip'

const Chip = ({ label, color, size, variant = '' }) => {
	return (
		<MuiChip
			label={label}
			color={color}
			size={size}
			variant={variant}
		/>
	)
}

Chip.propTypes = {
	label: PropTypes.string.isRequired,
	color: PropTypes.string.isRequired,
	size: PropTypes.string.isRequired,
	variant: PropTypes.string
}

export default Chip
