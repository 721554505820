import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import Inputs from '../../../../../components/mui/Inputs'
import Layout from '../../../../../components/mui/Layout'
import { setFormData, setPermissionStore, setPermissionUpdateById } from '../../../../../redux/actions/dashboard/settings/permissions/permissionAction'
import { Permission } from '../../../../../services/permissions/Permission'
import { initialValuesPermission as initialValues } from '../../../../../helpers/variablesInitialValues'

const FormComponent = () => {
	const dispatch = useDispatch()
	const isLoadingForm = useSelector( state => state.permission.isLoadingForm)
	const isLoadingTable = useSelector( state => state.permission.isLoadingTable)
	const formType = useSelector( state => state.permission.formType)
	const formData = useSelector( state => state.permission.formData)
	
	const validationSchema = () => (
		Yup.object({
			name: Yup.string()
				.required('El nombre es requerido'),
			publicName: Yup.string()
				.required('El nombre público es requerido'),
			description: Yup.string()
				.required('La descripción es requerida'),
			group: Yup.string()
				.required('El grupo es requerido')
		})
	)
	
	const hanbleSubmit = async (values, { setSubmitting, resetForm, setValues }) => {
		setSubmitting(false)
		
		const param = {
			name: formData.name,
			public_name: formData.publicName,
			description: formData.description,
			group: formData.group
		}
		
		if (formType === 'store') {
			await dispatch( setPermissionStore(param) )
		} else if (formType === 'show') {
			await dispatch( setPermissionUpdateById(formData.id, param) )
		}
		
		isLoadingTable && resetForm({ values: initialValues })
		isLoadingTable && setValues(initialValues)
	}
	
	const handleOnChange = ({ target }) => dispatch( setFormData( { ...formData, [target.name]: target.value }) )
	
	return (
		<Formik
			initialValues={formData}
			validationSchema={validationSchema}
			onSubmit={hanbleSubmit}
		>
			{({
				  values,
				  errors,
				  handleChange,
				  handleBlur,
				  isValid,
				  dirty
			  }) => (
				<Form noValidate>
					<Inputs.TextBox
						type="text"
						variant="outlined"
						size="small"
						name="name"
						label="Nombre"
						placeholder="Ingrese nombre..."
						hasLabelFixed={true}
						value={formData.name}
						onChange={e => {
							handleOnChange(e)
							handleChange(e)
						}}
						onBlur={handleBlur}
						error={errors.name ? true : false}
						helperText={errors.name ? errors.name : ''}
						className="font-family-roboto-medium text-dark fs-5 w-100"
					/>
					
					<Inputs.TextBox
						type="text"
						variant="outlined"
						size="small"
						name="publicName"
						label="Nombre público"
						placeholder="Ingrese nombre público..."
						hasLabelFixed={true}
						value={formData.publicName}
						onChange={e => {
							handleOnChange(e)
							handleChange(e)
						}}
						onBlur={handleBlur}
						error={errors.publicName ? true : false}
						helperText={errors.publicName ? errors.publicName : ''}
						className="font-family-roboto-medium text-dark fs-5 w-100 mt-3"
					/>
					
					<Inputs.TextBox
						type="text"
						variant="outlined"
						size="small"
						name="description"
						label="Descripción"
						placeholder="Ingrese descripción..."
						hasLabelFixed={true}
						value={formData.description}
						onChange={e => {
							handleOnChange(e)
							handleChange(e)
						}}
						onBlur={handleBlur}
						error={errors.description ? true : false}
						helperText={errors.description ? errors.description : ''}
						className="font-family-roboto-medium text-dark fs-5 w-100 mt-3"
					/>
					
					<Inputs.TextBox
						type="text"
						variant="outlined"
						size="small"
						name="group"
						label="Grupo"
						placeholder="Ingrese grupo..."
						hasLabelFixed={true}
						value={formData.group}
						onChange={e => {
							handleOnChange(e)
							handleChange(e)
						}}
						onBlur={handleBlur}
						error={errors.group ? true : false}
						helperText={errors.group ? errors.group : ''}
						className="font-family-roboto-medium text-dark fs-5 w-100 mt-3"
					/>
					
					<Permission
						permission={`${formType === 'store' ? 'permissions.store' : 'permissions.update'}`}
					>
						<Inputs.LoadingButton
							isDisabled={formType === 'store' ? !(dirty && isValid) : false}
							variant="contained"
							color="primary"
							isLoading={isLoadingForm}
							isLoadingPosition="start"
							startIcon={formType === 'store' ? <Layout.Icons.SaveIcon height={18} width={18} /> : <Layout.Icons.EditIcon height={18} width={18} />}
							label={`${formType === 'store' ? 'Guardar' : 'Actualizar'}`}
							type="submit"
							className="font-family-roboto-regular fs-6 mt-2 d-flex float-end"
						/>
					</Permission>
				</Form>
			)}
		</Formik>
	)
}

export default FormComponent
