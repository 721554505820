import ReactJwtDecode from 'jwt-decode'
import { useSelector } from 'react-redux'

const PermissionRoutes = permission => {
	const accessToken = useSelector(state => state.auth.accessToken)
	
	if (accessToken !== '') {
		const decode = ReactJwtDecode(accessToken)
		
		const canShowPermission = decode.permissions.find(value => value === permission)
		
		return canShowPermission ? true : false
	}
	
	return false
}

export default PermissionRoutes
