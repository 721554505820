import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'
import Feedback from '../../../../../components/mui/Feedback'
import Inputs from '../../../../../components/mui/Inputs'
import Layout from '../../../../../components/mui/Layout'
import PermissionsRowComponent from './PermissionsRowComponent'
import { getPermissionArrayToDropdown } from '../../../../../redux/actions/dashboard/settings/permissions/permissionAction'
import { setFormData, setRoleAssignPermissionsById } from '../../../../../redux/actions/dashboard/settings/roles/roleAction'
import { Permission } from '../../../../../services/permissions/Permission'

const PermissionsComponent = ({ name, isOpen, setIsOpen }) => {
	const dispatch = useDispatch()
	const isLoadingPermission = useSelector(state => state.permission.isLoadingForm)
	const isLoadingForm = useSelector(state => state.role.isLoadingForm)
	const permissions = useSelector(state => state.permission.arrayListToDropdown)
	const formData = useSelector(state => state.role.formData)
	
	const getPermissionsArray = async () => isOpen && await dispatch( getPermissionArrayToDropdown(formData) )
	
	useEffect(() => {
		getPermissionsArray()
	}, [isOpen])
	
	const handleChange = (id, name) => {
		const array = formData.permissions
		const find = array.find(item => item.name === name)
		
		if (find) {
			array.forEach((item, index, map) => {
				if (item.name === name) {
					map.splice(index, 1)
				}
			})
		} else {
			array.push({ id: id, name: name })
		}
		
		const param = {
			id: formData.id,
			name: formData.name,
			permissions: array
		}

		dispatch( setFormData(param) )
	}
	
	const handleAssignPermissionById = async () => {
		await dispatch( setRoleAssignPermissionsById(formData.id, formData) )
		!isLoadingForm && await handleCloseModal()
	}
	
	const handleCloseModal = async () => await setIsOpen(false)
	
	return (
		<Feedback.Modal
			maxWidth="md"
			isOpen={isOpen}
			isClose={handleCloseModal}
			title={`Asignar permisos al rol ${name}`}
			className="vh-100"
			hasAction={true}
			contentActions={
				<Permission
					permission="roles.assignPermissions"
				>
					<Inputs.LoadingButton
						variant="contained"
						color="primary"
						isLoading={isLoadingForm}
						isLoadingPosition="start"
						startIcon={<Layout.Icons.EditIcon height={18} width={18} />}
						label="Guardar"
						type="button"
						className="font-family-roboto-regular fs-6"
						onClick={handleAssignPermissionById}
					/>
				</Permission>
			}
		>
			<div className="row">
				{isLoadingPermission && (
					<div className="col-sm-12 vh-100 bottom-100 text-center">
						<CircularProgress
							style={{height: '32px', width: '32px'}}
							color="inherit"
						/>
					</div>
				)}
				{!isLoadingPermission && (
					permissions.length > 0 && (
						permissions.map(({ group, items }, number) => (
							<PermissionsRowComponent
								key={number}
								group={group}
								items={items}
								handleChange={handleChange}
							/>
						))
					)
				)}
			</div>
		</Feedback.Modal>
	)
}

PermissionsComponent.propTypes = {
	name: PropTypes.string.isRequired,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired
}

export default PermissionsComponent
