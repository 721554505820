import React from 'react'
import PropTypes from 'prop-types'
import Mail from '@mui/icons-material/Mail'

const MailIcon = ({ height, width, ...more }) => {

  return (
    <Mail
      height={height}
      width={width}
      {...more}
    />
  )
}

MailIcon.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired
}

export default MailIcon
