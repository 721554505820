import React from 'react'
import Layout from '../../../components/mui/Layout'
import Logo from '../../../components/mui/Logo'
import FormComponent from './components/FormComponent'
import '../Auth.scss'

const LoginPage = () => {
  
  return (
    <Layout.Page
      title="Login"
    >
      <Layout.Card className="form-container border-radius-6 mt-5">
        <Layout.CardContent className="m-0 p-4">
          <div className="row">
            <div className="col-sm-12">
              <Logo
                className="d-block mx-auto logo"
              />
            </div>
            <div className="col-sm-12 mt-4">
              <FormComponent />
            </div>
          </div>
        </Layout.CardContent>
        <Layout.CardActions
          to="/forgot-password"
          label="¿Has olvidado la contraseña?"
        />
      </Layout.Card>
    </Layout.Page>
  )
}

export default LoginPage
