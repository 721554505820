import React from 'react'
import PropTypes from 'prop-types'
import MuiCardActions from '@mui/material/CardActions'
import Navigation from '../Navigation'
import Layout from '../Layout'

const CardActions = ({ to, label }) => {
	
	return (
		<MuiCardActions className="m-0 pb-4 pt-0">
			<div className="row">
				<div className="col-sm-12 mb-2">
					<Layout.Divider
						variant="middle"
						color="light"
						textAlign="center"
						text="O"
					/>
				</div>
				<div className="col-sm-12 mb-1 text-center">
					<Navigation.NavLink
						to={to}
						label={label}
						className="font-family-roboto-regular fs-6 link-primary"
					/>
				</div>
				<div className="col-sm-12 text-center">
					<div className="font-family-roboto-medium fs-6 text-grey">
						<small>Powered by Fieloo</small>
					</div>
				</div>
			</div>
		</MuiCardActions>
	)
}

CardActions.propTypes = {
	to: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired
}

export default CardActions
