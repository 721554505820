import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'
import Box from '@mui/material/Box'

const Page = forwardRef(({ title, meta = null, children }, ref) => (
	<>
		<Helmet>
			<title>{`${title} | Gestión Fieloo - Signing Everywhere`}</title>
			{meta}
		</Helmet>
		
		<Box ref={ref}>
			{children}
		</Box>
	</>
))

Page.propTypes = {
	title: PropTypes.string.isRequired,
	meta: PropTypes.any,
	children: PropTypes.any.isRequired
}

export default Page
