import { useSelector } from 'react-redux';
import { initialValuesProfile as initialValues } from '../../../../../helpers/variablesInitialValues'
import * as types from '../../../../actionsTypes/dashboard/settings/profileTypes'
import { setUserData } from '../../../auth/authAction'
import { setChangeProfileById, setChangeProfilePasswordById } from './services/userServices'
import { swalSuccess } from '../../../../../services/sweetalert'
import errorServices from '../../../../../services/errors'

export const changeProfileById = param => (
	async (dispatch) => {
		await dispatch( setIsLoadingForm(true) )
		
		await setChangeProfileById(param).then(({ data }) => {
			const userData = useSelector( state => state.auth.userData)
			const object = {
				avatar: userData.avatar,
				firstName: param.firstName === userData.firstName ? userData.firstName : param.firstName,
				lastName: param.lastName === userData.lastName ? userData.lastName : param.lastName,
				email: param.email === userData.email ? userData.email : param.email,
				realState: userData.realState,
				roles: userData.roles
			}
			swalSuccess('Captación exitosa', data.data)
			dispatch( setUserData(object)  )
			dispatch( setProfileReset() )
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoadingForm(false) )
	}
)

export const changeProfilePasswordById = param => (
	async (dispatch) => {
		await dispatch( setIsLoadingForm(true) )
		
		await setChangeProfilePasswordById(param).then(({ data }) => {
			swalSuccess('Captación exitosa', data.data)
			dispatch( setProfileReset() )
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoadingForm(false) )
	}
)

export const setProfileReset = () => (
	async (dispatch) => {
		await dispatch( setFormData(initialValues) )
		await dispatch( setIsOpenModal(false) )
		await dispatch( setIsLoadingForm(false) )
	}
)

export const setIsOpenModal = value => ({
	type: types.IS_OPEN_MODAL_PROFILE,
	payload: value
})

export const setIsLoadingForm = value => ({
	type: types.IS_LOADING_FORM_PROFILE,
	payload: value
})

export const setFormData = value => ({
	type: types.FORM_DATA_PROFILE,
	payload: value
})
