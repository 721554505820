import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import Layout from '../../../../components/mui/Layout'
import Inputs from '../../../../components/mui/Inputs'
import { passwordReset } from '../../../../redux/actions/auth/passwordResetAction'
import { initialValuesPasswordReset as initialValues } from '../../../../helpers/variablesInitialValues'

const FormComponent = ({ uID }) => {
	const dispatch = useDispatch()
	const isLoadingForm = useSelector( state => state.passwordReset.isLoadingForm)
	
	const validationSchema = () => (
		Yup.object({
			password: Yup.string()
			.min(6, 'La contraseña debe contener al menos 6 caracteres')
			.required('La contraseña es requerida'),
			password_confirm: Yup.string()
			.min(6, 'La confirmación de la contraseña debe contener al menos 6 caracteres')
			.required('La confirmación de la contraseña es requerida')
			.oneOf([Yup.ref('password'), null], 'La confirmación debe ser igual a la contraseña')
		})
	)
	
	useEffect(() => {
	}, [uID])
	
	const hanbleSubmit = async ({ password, password_confirm }, { setSubmitting, resetForm, setValues }) => {
		await setSubmitting(false)
		
		const param = {
			uID: uID,
			password: password,
			password_confirm: password_confirm
		}
		
		await dispatch( passwordReset(param) )
		
		await resetForm({ values: initialValues })
		await setValues(initialValues)
	}
	
	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={hanbleSubmit}
		>
			{({
				  values,
				  errors,
				  handleChange,
				  handleBlur,
				  isValid,
				  dirty
			  }) => (
				<Form noValidate>
					<Inputs.TextBoxPassword
						variant="outlined"
						size="small"
						name="password"
						label="Contraseña"
						placeholder="Ingrese contraseña..."
						hasLabelFixed={true}
						value={values.password}
						onChange={handleChange}
						onBlur={handleBlur}
						hasError={errors.password ? true : false}
						errorMessage={errors.password ? errors.password : ''}
						className="font-family-roboto-medium text-dark fs-5 mt-3 w-100"
					/>
					
					<Inputs.TextBoxPassword
						variant="outlined"
						size="small"
						name="password_confirm"
						label="Confirmar contraseña"
						placeholder="Ingrese confirmación..."
						hasLabelFixed={true}
						value={values.password_confirm}
						onChange={handleChange}
						onBlur={handleBlur}
						hasError={errors.password_confirm ? true : false}
						errorMessage={errors.password_confirm ? errors.password_confirm : ''}
						className="font-family-roboto-medium text-dark fs-5 mt-3 w-100"
					/>
					
					<Inputs.LoadingButton
						isDisabled={!(dirty && isValid)}
						variant="contained"
						color="primary"
						isLoading={isLoadingForm}
						isLoadingPosition="start"
						startIcon={<Layout.Icons.LockOpenIcon height={18} width={18} />}
						label="Resetear contraseña"
						type="submit"
						className="font-family-roboto-regular fs-6 mt-2 w-100"
					/>
				</Form>
			)}
		</Formik>
	)
}

FormComponent.propTypes = {
	uID: PropTypes.string.isRequired
}

export default FormComponent
