import React from 'react'
import PropTypes from 'prop-types'
import MuiIconButton from '@mui/material/IconButton'

const IconButton = ({ color, size= 'medium', onClick = null, children, ...more  }) => {
	
	return (
		<MuiIconButton
			color={color}
			size={size}
			onClick={onClick}
			{...more}
		>
			{children}
		</MuiIconButton>
	)
}

IconButton.propTypes = {
	color: PropTypes.string.isRequired,
	size: PropTypes.string,
	onClick: PropTypes.func,
	children: PropTypes.any.isRequired
}

export default IconButton
