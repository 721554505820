import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import Layout from '../../../../../components/mui/Layout'
import PermissionsComponent from './PermissionsComponent'
import DeleteComponent from './DeleteComponent'
import { setIsLoadingTable, setIsOpenModal, setFormType, getRoleShowById } from '../../../../../redux/actions/dashboard/settings/roles/roleAction'
import { Permission } from '../../../../../services/permissions/Permission'

const TableRowComponent = ({ id, name }) => {
	const dispatch = useDispatch()
	const [isOpenModalPermission, setIsOpenModalPermission] = useState(false)
	const [isOpenModalDelete, setIsOpenModalDelete] = useState(false)
	
	useEffect(() => {
	}, [id, name])
	
	const handleIsOpenModal = async () => {
		await dispatch( getRoleShowById(id) )
		dispatch( setFormType('show') )
		dispatch( setIsLoadingTable(false) )
		dispatch( setIsOpenModal(true) )
	}
	
	const handleIsOpenModalPermission = async () => {
		await dispatch( getRoleShowById(id) )
		dispatch( setIsLoadingTable(false) )
		setIsOpenModalPermission(true)
	}
	
	const handleIsOpenModalDelete = () => {
		dispatch( setIsLoadingTable(false) )
		setIsOpenModalDelete(true)
	}
	
	return (
		<>
			<tr>
				<td width="80%">
					<div className="font-family-roboto-regular fs-5">{ name }</div>
				</td>
				<td width="20%">
					<div className="text-center">
						<Permission
							permission="roles.permissionsByRole"
						>
							<Layout.Tooltip
								title="Asignar permisos"
								placement="top"
							>
								<Layout.IconButton
									color="inherit"
									onClick={handleIsOpenModalPermission}
								>
									<Layout.Icons.ZoomInIcon
										height={16}
										width={16}
										className="text-dark"
									/>
								</Layout.IconButton>
							</Layout.Tooltip>
						</Permission>
						<Permission
							permission="roles.show"
						>
							<Layout.Tooltip
								title="Editar"
								placement="top"
							>
								<Layout.IconButton
									color="inherit"
									onClick={handleIsOpenModal}
								>
									<Layout.Icons.EditIcon
										height={16}
										width={16}
										className="text-warning"
									/>
								</Layout.IconButton>
							</Layout.Tooltip>
						</Permission>
						<Permission
							permission="roles.delete"
						>
							<Layout.Tooltip
								title="Eliminar"
								placement="top"
							>
								<Layout.IconButton
									color="inherit"
									onClick={handleIsOpenModalDelete}
								>
									<Layout.Icons.DeleteIcon
										height={16}
										width={16}
										className="text-danger"
									/>
								</Layout.IconButton>
							</Layout.Tooltip>
						</Permission>
					</div>
				</td>
			</tr>
			<Permission
				permission="roles.permissionsByRole"
			>
				<PermissionsComponent
					name={name}
					isOpen={isOpenModalPermission}
					setIsOpen={setIsOpenModalPermission}
				/>
			</Permission>
			<Permission
				permission="roles.delete"
			>
				<DeleteComponent
					id={id}
					name={name}
					isOpen={isOpenModalDelete}
					setIsOpen={setIsOpenModalDelete}
				/>
			</Permission>
		</>
	)
}

TableRowComponent.propTypes = {
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired
}

export default TableRowComponent
