import AccountCircleIcon from './icons/AccountCircleIcon'
import AddIcon from './icons/AddIcon'
import AdminPanelSettingsIcon from './icons/AdminPanelSettingsIcon'
import BarChartIcon from './icons/BarChartIcon'
import CheckIcon from './icons/CheckIcon'
import CloseIcon from './icons/CloseIcon'
import DeleteIcon from './icons/DeleteIcon'
import EditIcon from './icons/EditIcon'
import ExitToAppIcon from './icons/ExitToAppIcon'
import ExpandLessIcon from './icons/ExpandLessIcon'
import ExpandMoreIcon from './icons/ExpandMoreIcon'
import GppBadIcon from './icons/GppBadIcon'
import KeyboardArrowUpIcon from './icons/KeyboardArrowUpIcon'
import ListIcon from './icons/ListIcon'
import LockOpenIcon from './icons/LockOpenIcon'
import MailIcon from './icons/MailIcon'
import MailOutlineIcon from './icons/MailOutlineIcon'
import MenuIcon from './icons/MenuIcon'
import MoreIcon from './icons/MoreIcon'
import NavigateBeforeIcon from './icons/NavigateBeforeIcon'
import NavigateNextIcon from './icons/NavigateNextIcon'
import RemoveIcon from './icons/RemoveIcon'
import SaveIcon from './icons/SaveIcon'
import SettingsIcon from './icons/SettingsIcon'
import VerifiedUserIcon from './icons/VerifiedUserIcon'
import VisibilityIcon from './icons/VisibilityIcon'
import VisibilityOffIcon from './icons/VisibilityOffIcon'
import ZoomInIcon from './icons/ZoomInIcon'

const Icons = {
  AccountCircleIcon,
  AddIcon,
  AdminPanelSettingsIcon,
  BarChartIcon,
  CheckIcon,
  CloseIcon,
  DeleteIcon,
  EditIcon,
  ExitToAppIcon,
  ExpandLessIcon,
  ExpandMoreIcon,
  GppBadIcon,
  KeyboardArrowUpIcon,
  ListIcon,
  LockOpenIcon,
  MailIcon,
  MailOutlineIcon,
  MenuIcon,
  MoreIcon,
  NavigateBeforeIcon,
  NavigateNextIcon,
  RemoveIcon,
  SaveIcon,
  SettingsIcon,
  VerifiedUserIcon,
  VisibilityIcon,
  VisibilityOffIcon,
  ZoomInIcon,
}

export default Icons
