import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Feedback from '../../../../../components/mui/Feedback'
import Navigation from '../../../../../components/mui/Navigation'
import FormProfileComponent from './FormProfileComponent'
import FormPasswordResetComponent from './FormPasswordResetComponent'
import { setFormData, setIsOpenModal } from '../../../../../redux/actions/dashboard/settings/users/profileAction'
import { Permission } from '../../../../../services/permissions/Permission'
import { initialValuesProfile as initialValues } from '../../../../../helpers/variablesInitialValues'

const ModalProfileComponent = () => {
	const dispatch = useDispatch()
	const isOpenModal = useSelector( state => state.profile.isOpenModal)
	const [value, setValue] = useState(0)
	
	const handleLoad = () => isOpenModal && dispatch( setFormData(initialValues) )
	
	useEffect(() => {
		handleLoad()
	}, [isOpenModal])
	
	const handleChange = (event, newValue) => setValue(newValue)
	
	const handleCloseModal = () => dispatch( setIsOpenModal(false) )
	
	const handlePropsTabs = index => {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		}
	}
	
	return (
		<Feedback.Modal
			maxWidth="md"
			isOpen={isOpenModal}
			isClose={handleCloseModal}
			title="Actualización del perfil"
		>
			<Tabs
				value={value}
				onChange={handleChange}
				centered
			>
				<Tab
					label="Perfil"
					{...handlePropsTabs(0)}
				/>
				<Tab
					label="Seguridad"
					{...handlePropsTabs(1)}
				/>
			</Tabs>
			
			<Navigation.TabPanel
				value={value}
				index={0}
			>
				<Permission
					permission="users.changeProfile"
				>
					<FormProfileComponent />
				</Permission>
			</Navigation.TabPanel>
			<Navigation.TabPanel
				value={value}
				index={1}
			>
				<Permission
					permission="users.changeProfilePassword"
				>
					<FormPasswordResetComponent />
				</Permission>
			</Navigation.TabPanel>
		</Feedback.Modal>
	)
}

export default ModalProfileComponent
