import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Layout from '../../../../components/mui/Layout'
import TableComponent from './components/TableComponent'
import ModalComponent from './components/ModalComponent'

const UserPage = () => {
	
	return (
		<Layout.Page
			title="Usuarios"
		>
			<Layout.HeaderContent
				title="Módulo de Usuarios"
				subtitle="Listado de Usuarios"
			/>
			
			<Card className="border-radius-6 mt-3">
				<CardContent className="m-0 p-4">
					<TableComponent />
				</CardContent>
			</Card>
			
			<ModalComponent />
		</Layout.Page>
	)
}

export default UserPage
