import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { unstable_HistoryRouter as HistoryRouter, Routes, Route } from 'react-router-dom'
import PublicRouter from './PublicRouter'
import ProtectedRouter from './ProtectedRouter'
import axios from '../services/axios'

const AppRouter = ({ history }) => {
	const isAuthenticated = useSelector( state  => state.auth.isAuthenticated)
	const tokenType = useSelector( state  => state.auth.tokenType)
	const accessToken = useSelector( state  => state.auth.accessToken)
	const userData = useSelector( state  => state.auth.userData)
	
	useEffect(() => {
		const path = history.location.pathname
		
		if (isAuthenticated) {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			if (path === '/' || path === '/forgot-password' || path === '/password-reset' || path === '/verification') {
				history.push('dashboard/main')
			} else {
				history.push(path)
			}
		} else {
			axios.defaults.headers.common['Authorization'] = ''
			
			if (path === '/' || path.indexOf('dashboard/') === 1) {
				history.push('/')
			} else {
				history.push(path)
			}
		}
	}, [history, isAuthenticated, tokenType, accessToken, userData])
	
	return (
		<HistoryRouter history={history}>
			<Routes>
				<Route path="/*" element={<PublicRouter />} />
				<Route path="/dashboard/*" element={<ProtectedRouter />} />
			</Routes>
		</HistoryRouter>
	)
}

AppRouter.propTypes = {
	history: PropTypes.object.isRequired
}

export default AppRouter
