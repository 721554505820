import React from 'react'
import PropTypes from 'prop-types'

const TableHead = ({ columns }) => {

	return (
		<thead className="table-dark">
			{columns.length > 0 && (
				<tr>
					{columns.map(({ label, align, width }, number) => (
						<td
							key={number}
							align={align}
							width={width}
						>
							<div className="font-family-roboto-regular fs-5">
								{label}
							</div>
						</td>
					))}
				</tr>
			)}
		</thead>
	)
}

TableHead.propTypes = {
	columns: PropTypes.array.isRequired
}

export default TableHead
