import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import Layout from '../../../../../components/mui/Layout'
import PermissionsComponent from './PermissionsComponent'
import DeleteComponent from './DeleteComponent'
import { setIsLoadingTable, setIsOpenModal, setFormType, getUserShowById } from '../../../../../redux/actions/dashboard/settings/users/userAction'
import { Permission } from '../../../../../services/permissions/Permission'
import avatarDefault from '../../../../../assets/images/avatar-default.jpg'

const TableRowComponent = ({ id, avatar, name, email, role, company, status, verified }) => {
	const dispatch = useDispatch()
	const userData = useSelector(state => state.auth.userData)
	const [isOpenModalPermission, setIsOpenModalPermission] = useState(false)
	const [isOpenModalDelete, setIsOpenModalDelete] = useState(false)
	
	useEffect(() => {
	}, [id, avatar, name, email, role, company, status, verified])
	
	const handleIsOpenModal = async () => {
		await dispatch( getUserShowById(id) )
		dispatch( setFormType('show') )
		dispatch( setIsLoadingTable(false) )
		dispatch( setIsOpenModal(true) )
	}
	
	const handleIsOpenModalPermission = async () => {
		await dispatch( getUserShowById(id) )
		dispatch( setIsLoadingTable(false) )
		setIsOpenModalPermission(true)
	}
	
	const handleIsOpenModalDelete = () => {
		dispatch( setIsLoadingTable(false) )
		setIsOpenModalDelete(true)
	}
	
	return (
		<>
			<tr>
				<td width="5%">
					<div className="mx-auto justify-content-center text-center">
						<Layout.Avatar
							alt="avatar"
							src={avatar === null ? avatarDefault : avatar}
							size="sm"
							icon={false}
						/>
					</div>
				</td>
				<td width={`${userData.roles[0] === 'Super Administrador' ? '30%' : '40%'}`}>
					<div className="text-wrap text-truncate">
						<div className="font-family-roboto-regular fs-5">{ name }</div>
						<div className="font-family-roboto-semibold fs-6">Rol: { role[0] }</div>
					</div>
				</td>
				<td width={`${userData.roles[0] === 'Super Administrador' ? '25%' : '30%'}`}>
					<div className="text-wrap text-truncate">
						<div className="font-family-roboto-regular fs-5">{ email }</div>
					</div>
				</td>
				{userData.roles[0] === 'Super Administrador' && (
					<td width="15%">
						<div className="text-center text-wrap text-truncate">
							<div className="font-family-roboto-regular fs-5">{ company === null ? '' : company }</div>
						</div>
					</td>
				)}
				<td width="5%">
					<div className="text-center">
						<Layout.Chip
							label={status}
							color={`${status === 'Active' ? 'success' : 'error'}`}
							size="medium"
						/>
					</div>
				</td>
				<td width="20%">
					<div className="text-center">
						<Layout.Tooltip
							title={`Usuario ${verified === 'Verified' ? 'verificado' : 'no verificado'}`}
							placement="top"
						>
							{verified === 'Verified' && (
								<Layout.Icons.VerifiedUserIcon
									height={18}
									width={18}
									className="text-success"
								/>
							)}
							{verified === 'Not Verified' && (
								<Layout.Icons.GppBadIcon
									height={18}
									width={18}
									className="text-primary"
								/>
							)}
						</Layout.Tooltip>
						<Permission
							permission="users.permissionsByUser"
						>
							<Layout.Tooltip
								title="Asignar permisos"
								placement="top"
							>
								<Layout.IconButton
									color="inherit"
									onClick={handleIsOpenModalPermission}
								>
									<Layout.Icons.ZoomInIcon
										height={16}
										width={16}
										className="text-dark"
									/>
								</Layout.IconButton>
							</Layout.Tooltip>
						</Permission>
						<Permission
							permission="users.show"
						>
							<Layout.Tooltip
								title="Editar"
								placement="top"
							>
								<Layout.IconButton
									color="inherit"
									onClick={handleIsOpenModal}
								>
									<Layout.Icons.EditIcon
										height={16}
										width={16}
										className="text-warning"
									/>
								</Layout.IconButton>
							</Layout.Tooltip>
						</Permission>
						<Permission
							permission="users.delete"
						>
							<Layout.Tooltip
								title="Eliminar"
								placement="top"
							>
								<Layout.IconButton
									color="inherit"
									onClick={handleIsOpenModalDelete}
								>
									<Layout.Icons.DeleteIcon
										height={16}
										width={16}
										className="text-danger"
									/>
								</Layout.IconButton>
							</Layout.Tooltip>
						</Permission>
					</div>
				</td>
			</tr>
			<Permission
				permission="users.permissionsByUser"
			>
				<PermissionsComponent
					name={name}
					isOpen={isOpenModalPermission}
					setOpen={setIsOpenModalPermission}
				/>
			</Permission>
			<Permission
				permission="users.delete"
			>
				<DeleteComponent
					id={id}
					name={name}
					isOpen={isOpenModalDelete}
					setOpen={setIsOpenModalDelete}
				/>
			</Permission>
		</>
	)
}

TableRowComponent.propTypes = {
	id: PropTypes.string.isRequired,
	avatar: PropTypes.string,
	name: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
	role: PropTypes.array.isRequired,
	company: PropTypes.string,
	status: PropTypes.string.isRequired,
	verified: PropTypes.string.isRequired
}

export default TableRowComponent
