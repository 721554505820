import React from 'react'
import PropTypes from 'prop-types'
import MuiLoadingButton from '@mui/lab/LoadingButton'

const LoadingButton = (props) => {
  const {
    isDisabled = false,
    variant,
    size = 'medium',
    color,
    isLoading = false,
    isLoadingPosition = '',
    label,
    type,
    startIcon = '',
    endIcon = '',
    onClick = null,
    ...more
  } = props

  return (
    <MuiLoadingButton
      disabled={isDisabled}
      variant={variant}
      size={size}
      color={color}
      loading={isLoading}
      loadingPosition={isLoadingPosition}
      type={type}
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={onClick}
      {...more}
    >
      {label}
    </MuiLoadingButton>
  )
}

LoadingButton.propTypes = {
  isDisabled: PropTypes.bool,
  variant: PropTypes.string.isRequired,
  size: PropTypes.string,
  color: PropTypes.any.isRequired,
  isLoading: PropTypes.bool,
  isLoadingPosition: PropTypes.string,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  startIcon: PropTypes.object,
  endIcon: PropTypes.object,
  onClick: PropTypes.func
}

export default LoadingButton
