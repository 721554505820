import React from 'react'
import PropTypes from 'prop-types'
import Feedback from '../Feedback'

const TableRowNoFound = ({ columns }) => {
	return (
		<>
			{columns.length > 0 && (
				<tr>
					{columns.map(({ width }, number) => (
						<td
							key={number}
							width={width}
						>
							<Feedback.Skeleton
								variant="text"
								width="100%"
								height={35}
							/>
						</td>
					))}
				</tr>
			)}
		</>
	)
}

TableRowNoFound.propTypes = {
	columns: PropTypes.array.isRequired
}

export default TableRowNoFound
