import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import Feedback from '../../../../components/mui/Feedback'
import Layout from '../../../../components/mui/Layout'
import Inputs from '../../../../components/mui/Inputs'
import FormComponent from "./FormComponent"
import { verification, resendVerification } from '../../../../redux/actions/auth/passwordResetAction'

const PasswordResetComponent = ({ uID }) => {
	const dispatch = useDispatch()
	const isLoading = useSelector( state => state.loading.isLoading)
	const isLoadingForm = useSelector( state => state.passwordReset.isLoadingForm)
	const isActiveButton = useSelector( state => state.passwordReset.isActiveButton)
	const isActiveForm = useSelector( state => state.passwordReset.isActiveForm)
	const isActiveAlert = useSelector( state => state.passwordReset.isActiveAlert)
	const colorStatus = useSelector( state => state.passwordReset.colorStatus)
	const textStatus = useSelector( state => state.passwordReset.textStatus)

	const handleResendVerification = async () => isActiveButton && await dispatch( resendVerification({ uID: uID }) )
	
	const handleVerification = async () => await dispatch( verification({ uID: uID }) )
	
	useEffect(() => {
		handleVerification()
	}, [uID])
	
	return (
		<>
			{!isLoading && isActiveAlert && (
				<div className={`animated animate__fadeIn mb-2 alert alert-${colorStatus}`}>
					<div className="text-center font-family-roboto-medium fs-6">
						{textStatus}
					</div>
				</div>
			)}
			{isActiveButton && (
				<Inputs.LoadingButton
					variant="contained"
					color="primary"
					isLoading={isLoadingForm}
					isLoadingPosition="start"
					startIcon={<Layout.Icons.MailIcon height={18} width={18} />}
					label="Reenviar solicitud"
					type="button"
					className="animated animate__fadeIn font-family-roboto-regular fs-6 w-100"
					onClick={handleResendVerification}
				/>
			)}
			{isActiveForm && (
				<FormComponent uID={uID} />
			)}
			<Feedback.ModalLoading
				maxWidth="xs"
				isOpen={isLoading}
				title="Cargando..."
				content="Espere un momento por favor..."
			/>
		</>
	)
}

PasswordResetComponent.propTypes = {
	uID: PropTypes.string.isRequired
}

export default PasswordResetComponent
