const ROOT = 'dashboard/settings/users/'

export default {
	arrayList: `${ROOT}list`,
	arrayListToDropdown: `${ROOT}dropdown`,
	store: `${ROOT}store`,
	show: `${ROOT}show/`,
	update: `${ROOT}update/`,
	assignPermissions: `${ROOT}assign-permissions/`,
	changeProfile: `${ROOT}change-profile`,
	changeProfilePassword: `${ROOT}change-profile-password`,
	destroy: `${ROOT}delete/`,
}
