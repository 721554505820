import { initialValuesCompany as initialValues } from '../../../../../helpers/variablesInitialValues'
import * as types from '../../../../actionsTypes/dashboard/settings/companyTypes'
import { setIsLoading } from '../../../loadingAction'
import { getArrayList, getArrayListToDropdown, setStore, getShowById, setUpdateById, setDeleteById } from './services/companiesServices'
import { swalSuccess } from '../../../../../services/sweetalert'
import errorServices from '../../../../../services/errors'

export const getCompanyStateArray = param => (
	async (dispatch) => {
		await dispatch( setIsLoading(true) )
		
		await getArrayList(param).then(({ data }) => {
			dispatch( setPages(data.data.pages) )
			dispatch( setArrayList(data.data.records) )
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoading(false) )
	}
)

export const getCompanyStateArrayToDropdown = param => (
	async (dispatch) => {
		await dispatch( setIsLoadingForm(true) )
		
		await getArrayListToDropdown().then(({ data }) => {
			dispatch( setArrayListToDropdown(data.data.records) )
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoadingForm(false) )
	}
)

export const setCompanyStateStore = data => (
	async (dispatch) => {
		await dispatch( setIsLoadingForm(true) )
		
		await setStore(data).then(({ data }) => {
			dispatch( setRefresh() )
			swalSuccess('Captación exitosa', data.data)
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoadingForm(false) )
	}
)

export const getCompanyStateShowById = id => (
	async (dispatch) => {
		await dispatch( setIsLoadingForm(true) )
		
		await getShowById(id).then(({ data }) => {
			const object = {
				id: data.data.id,
				name: data.data.name,
				email: data.data.email,
				mobile: data.data.mobile,
				address: data.data.address
			}
			
			dispatch( setFormData(object) )
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoadingForm(false) )
	}
)

export const setCompanyStateUpdateById = (id, param) => (
	async (dispatch) => {
		await dispatch( setIsLoadingForm(true) )
		
		await setUpdateById(id, param).then(({ data }) => {
			dispatch( setRefresh() )
			swalSuccess('Captación exitosa', data.data)
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoadingForm(false) )
	}
)

export const setCompanyStateDeleteById = id => (
	async (dispatch) => {
		await dispatch( setIsLoading(true) )
		
		await setDeleteById(id).then(({ data }) => {
			dispatch( setRefresh() )
			swalSuccess('Eliminación exitosa', data.data)
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoading(false) )
	}
)

const setRefresh = () => (
	async (dispatch) => {
		await dispatch( setFormType('') )
		await dispatch( setFormData(initialValues) )
		await dispatch( setIsLoadingTable(true) )
		await dispatch( setIsLoadingForm(false) )
	}
)

export const setRealStateReset = () => (
	async (dispatch) => {
		await dispatch( setPerPage(10) )
		await dispatch( setPage(0) )
		await dispatch( setSearch('') )
		await dispatch( setPages(0) )
		await dispatch( setArrayList([]) )
		await dispatch( setArrayListToDropdown([]) )
		await dispatch( setFormType('') )
		await dispatch( setFormData(initialValues) )
		await dispatch( setIsLoadingTable(true) )
		await dispatch( setIsLoadingForm(false) )
	}
)

export const setPerPage = value => ({
	type: types.PER_PAGE_COMPANY,
	payload: value
})

export const setPage = value => ({
	type: types.PAGE_COMPANY,
	payload: value
})

export const setSearch = value => ({
	type: types.SEARCH_COMPANY,
	payload: value
})

export const setPages = value => ({
	type: types.PAGES_COMPANY,
	payload: value
})

export const setArrayList = value => ({
	type: types.ARRAY_LIST_COMPANY,
	payload: value
})

export const setArrayListToDropdown = value => ({
	type: types.ARRAY_LIST_TO_DROPDOWN_COMPANY,
	payload: value
})

export const setIsOpenModal = value => ({
	type: types.IS_OPEN_MODAL_COMPANY,
	payload: value
})

export const setIsLoadingTable = value => ({
	type: types.IS_LOADING_TABLE_COMPANY,
	payload: value
})

export const setIsLoadingForm = value => ({
	type: types.IS_LOADING_FORM_COMPANY,
	payload: value
})

export const setFormType = value => ({
	type: types.FORM_TYPE_COMPANY,
	payload: value
})

export const setFormData = value => ({
	type: types.FORM_DATA_COMPANY,
	payload: value
})
