export const perPages = [
	{id: 10, name: '10'},
	{id: 20, name: '20'},
	{id: 50, name: '50'},
	{id: 100, name: '100'},
	{id: 200, name: '200'},
	{id: 500, name: '500'}
]

export const permissionColumns = [
	{label: 'Nombre', align: 'left', width: '20%'},
	{label: 'Nombre público', align: 'left', width: '25%'},
	{label: 'Descripción', align: 'left', width: '30%'},
	{label: 'Grupo', align: 'center', width: '15%'},
	{label: '', align: 'center',  width: '10%'}
]

export const roleColumns = [
	{label: 'Nombre', align: 'left', width: '80%'},
	{label: '', align: 'center', width: '20%'}
]

export const userSuperAdminColumns = [
	{ label: '', align: 'left', width: '5%' },
	{ label: 'Nombres y Apellidos', align: 'left', width: '30%' },
	{ label: 'Email', align: 'left', width: '25%' },
	{ label: 'Inmobiliaria', align: 'center', width: '15%' },
	{ label: 'Estatus', align: 'center', width: '5%' },
	{ label: '', align: 'center', width: '20%' }
]

export const userAdminColumns = [
	{ label: '', align: 'left', width: '5%' },
	{ label: 'Nombres y Apellidos', align: 'left', width: '40%' },
	{ label: 'Email', align: 'left', width: '30%' },
	{ label: 'Estatus', align: 'center', width: '5%' },
	{ label: '', align: 'center', width: '20%' }
]

export const companyColumns = [
	{ label: 'Nombre', align: 'left', width: '35%' },
	{ label: 'Email', align: 'center', width: '35%' },
	{ label: 'Celular', align: 'center', width: '15%' },
	{ label: '', align: 'center', width: '15%' }
]
