import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import List from '@mui/material/List'
import ListItemComponent from './ListItemComponent'
import { Permission } from '../../../../../services/permissions/Permission'

const CollapseComponent = ({ children }) => {
	
	return (
		<List
			component="div"
      disablePadding
		>
			{children.length > 0 && (
				children.map(({ permission, icon, label, path, hasChildren, children }, number) => (
					<Permission
						key={number}
						permission={permission}
					>
						<ListItemComponent
							icon={icon}
							label={label}
							path={path}
							hasChildren={hasChildren}
							children={children}
						/>
					</Permission>
				))
			)}
		</List>
	)
}

CollapseComponent.propTypes = {
	children: PropTypes.array
}

export default CollapseComponent
