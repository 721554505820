import React from 'react'
import PropTypes from 'prop-types'
import ZoomIn from '@mui/icons-material/ZoomIn'

const ZoomInIcon = ({ height, width, ...more }) => {
	
	return (
		<ZoomIn
			height={height}
			width={width}
			{...more}
		/>
	)
}

ZoomInIcon.propTypes = {
	height: PropTypes.number.isRequired,
	width: PropTypes.number.isRequired
}

export default ZoomInIcon
