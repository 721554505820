import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Layout from '../../../components/mui/Layout'
import Logo from '../../../components/mui/Logo'
import FormComponent from './components/FormComponent'
import '../Auth.scss'
import favicon from "../../../assets/images/favicon/favicon.ico";

const VerificationPage = () => {
	const params = useParams()
	const uID = params.uID
	
	useEffect(() => {
	}, [uID])
	
	const handleAddMetaFavicon = () => <link rel="icon" href={favicon} />
	
	return (
		<Layout.Page
			title="Verificación"
			meta={handleAddMetaFavicon}
		>
			<Layout.Card className="form-container border-radius-6 mt-5">
				<Layout.CardContent className="m-0 p-4">
					<div className="row">
						<div className="col-sm-12">
							<Logo
								className="d-block mx-auto logo"
							/>
						</div>
						<div className="col-sm-12 mt-1">
							<FormComponent uID={uID} />
						</div>
					</div>
				</Layout.CardContent>
				<Layout.CardActions
					to="/"
					label="Volver al inicio de sesión"
				/>
			</Layout.Card>
		</Layout.Page>
	)
}

export default VerificationPage
