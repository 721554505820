import endPoints from './permissionEndpoints'
import axios from '../../../../../../services/axios'

const {
	arrayList,
	arrayListToDropdown,
	store,
	show,
	update,
	destroy
} = endPoints

export const getArrayList = async (data) => (
	await axios.post(arrayList, data)
)

export const getArrayListToDropdown = async () => (
	await axios.get(`${arrayListToDropdown}`)
)

export const setStore = async (data) => (
	await axios.post(store, data)
)

export const getShowById = async (id) => (
	await axios.get(`${show}${id}`)
)

export const setUpdateById = async (id, data) => (
	await axios.put(`${update}${id}`, data)
)

export const setDeleteById = async (id) => (
	await axios.delete(`${destroy}${id}`)
)


