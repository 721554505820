import * as types from '../../../actionsTypes/dashboard/settings/profileTypes'
import { initialValuesProfile as initialValues} from '../../../../helpers/variablesInitialValues'

const initialState = {
	isOpenModal: false,
	isLoadingForm: false,
	formData: initialValues
}

const profileReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.IS_OPEN_MODAL_PROFILE:
			return {
				...state,
				isOpenModal: payload
			}
		
		case types.IS_LOADING_FORM_PROFILE:
			return {
				...state,
				isLoadingForm: payload
			}
		
		case types.FORM_DATA_PROFILE:
			return {
				...state,
				formData: payload
			}
		
		default:
			return state
	}
}

export default profileReducer
