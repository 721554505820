import Button from './Button'
import CheckBox from './CheckBox'
import LoadingButton from './LoadingButton'
import Select from './Select'
import TextArea from './TextArea'
import TextBox from './TextBox'
import TextBoxPassword from './TextBoxPassword'

const index = {
  Button,
  CheckBox,
  LoadingButton,
  Select,
  TextArea,
  TextBox,
  TextBoxPassword,
}

export default index
