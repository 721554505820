import { initialValuesUser as initialValues } from '../../../../../helpers/variablesInitialValues'
import * as types from '../../../../actionsTypes/dashboard/settings/userTypes'
import { setIsLoading } from '../../../loadingAction'
import { getArrayList, getArrayListToDropdown, setStore, getShowById, setUpdateById, setAssignPermissionsById, setDeleteById } from './services/userServices'
import { swalSuccess } from '../../../../../services/sweetalert'
import errorServices from '../../../../../services/errors'

export const getUserArray = param => (
	async (dispatch) => {
		await dispatch( setIsLoading(true) )
		
		await getArrayList(param).then(({ data }) => {
			dispatch( setPages(data.data.pages) )
			dispatch( setArrayList(data.data.records) )
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoading(false) )
	}
)

export const getUserArrayToDropdown = () => (
	async (dispatch) => {
		await dispatch( setIsLoadingForm(true) )
		
		await getArrayListToDropdown().then(({ data }) => {
			dispatch( setArrayListToDropdown(data.data.records) )
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoadingForm(false) )
	}
)

export const setUserStore = param => (
	async (dispatch) => {
		await dispatch( setIsLoadingForm(true) )
		
		await setStore(param).then(({ data }) => {
			dispatch( setRefresh() )
			swalSuccess('Captación exitosa', data.data)
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoadingForm(false) )
	}
)

export const getUserShowById = id => (
	async (dispatch) => {
		await dispatch( setIsLoadingForm(true) )
		
		await getShowById(id).then(({ data }) => {
			const object = {
				id: data.data.id,
				firstName: data.data.first_name,
				lastName: data.data.last_name,
				email: data.data.email,
				mobile: data.data.mobile,
				phone: data.data.phone,
				address: data.data.address,
				role: data.data.role_id,
				permissions: data.data.permissions
			}
			dispatch( setFormData(object) )
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoadingForm(false) )
	}
)

export const setUserUpdateById = (id, param) => (
	async (dispatch) => {
		await dispatch( setIsLoadingForm(true) )
		
		await setUpdateById(id, param).then(({ data }) => {
			dispatch( setRefresh() )
			swalSuccess('Captación exitosa', data.data)
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoadingForm(false) )
	}
)

export const setUserAssignPermissionsById = (id, param) => (
	async (dispatch) => {
		await dispatch( setIsLoadingForm(true) )
		
		await setAssignPermissionsById(id, param).then(({ data }) => {
			dispatch( setRefresh() )
			swalSuccess('Asignación exitosa', data.data)
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoadingForm(false) )
	}
)

export const setUserDeleteById = id => (
	async (dispatch) => {
		await dispatch( setIsLoading(true) )
		
		await setDeleteById(id).then(({ data }) => {
			dispatch( setRefresh() )
			swalSuccess('Eliminación exitosa', data.data)
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoading(false) )
	}
)

const setRefresh = () => (
	async (dispatch) => {
		await dispatch( setFormType('') )
		await dispatch( setFormData(initialValues) )
		await dispatch( setIsLoadingTable(true) )
		await dispatch( setIsOpenModal(false) )
	}
)

export const setUserReset = () => (
	async (dispatch) => {
		await dispatch( setPerPage(10) )
		await dispatch( setPage(0) )
		await dispatch( setSearch('') )
		await dispatch( setPages(0) )
		await dispatch( setArrayList([]) )
		await dispatch( setArrayListToDropdown([]) )
		await dispatch( setFormType('') )
		await dispatch( setFormData(initialValues) )
		await dispatch( setIsOpenModal(false) )
		await dispatch( setIsLoadingTable(true) )
		await dispatch( setIsLoadingForm(false) )
	}
)

export const setPerPage = value => ({
	type: types.PER_PAGE_USER,
	payload: value
})

export const setPage = value => ({
	type: types.PAGE_USER,
	payload: value
})

export const setSearch = value => ({
	type: types.SEARCH_USER,
	payload: value
})

export const setPages = value => ({
	type: types.PAGES_USER,
	payload: value
})

export const setArrayList = value => ({
	type: types.ARRAY_LIST_USER,
	payload: value
})

export const setArrayListToDropdown = value => ({
	type: types.ARRAY_LIST_TO_DROPDOWN_USER,
	payload: value
})

export const setIsOpenModal = value => ({
	type: types.IS_OPEN_MODAL_USER,
	payload: value
})

export const setIsLoadingTable = value => ({
	type: types.IS_LOADING_TABLE_USER,
	payload: value
})

export const setIsLoadingForm = value => ({
	type: types.IS_LOADING_FORM_USER,
	payload: value
})

export const setFormType = value => ({
	type: types.FORM_TYPE_USER,
	payload: value
})

export const setFormData = value => ({
	type: types.FORM_DATA_USER,
	payload: value
})
