import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Layout from '../../../../../components/mui/Layout'
import TableRowComponent from './TableRowComponent'
import { getUserArray, setPerPage, setPage, setSearch, setIsLoadingTable, setIsOpenModal, setFormType, setFormData } from '../../../../../redux/actions/dashboard/settings/users/userAction'
import { Permission } from '../../../../../services/permissions/Permission'
import { userSuperAdminColumns, userAdminColumns, perPages } from '../../../../../helpers/variablesColumns'
import { initialValuesUser as initialValues } from '../../../../../helpers/variablesInitialValues'

const TableComponent = () => {
	const dispatch = useDispatch()
	const userData = useSelector(state => state.auth.userData)
	const isLoading = useSelector( state => state.loading.isLoading)
	const isLoadingTable = useSelector( state => state.user.isLoadingTable)
	const perPage = useSelector( state => state.user.perPage)
	const page = useSelector( state => state.user.page)
	const search = useSelector( state => state.user.search)
	const pages = useSelector( state => state.user.pages)
	const arrayList = useSelector( state => state.user.arrayList)
	
	const getArrayList = async () => {
		const data = {
			perPage: perPage,
			page: page,
			search: search === '' ? null : search
		}
		
		await dispatch( getUserArray(data) )
	}
	
	useEffect(() => {
		isLoadingTable && getArrayList()
	}, [perPage, page, isLoadingTable])
	
	const handleChangePerPage = ({ target }) => dispatch( setPerPage(target.value) )
	
	const handleChangePage = (event, value) => dispatch( setPage(value) )
	
	const handleChangeSearch = ({ target }) => dispatch( setSearch(target.value) )
	
	const handleKeyPressArrayList = async ({ charCode }) => charCode === 13 && await getArrayList()
	
	const handleIsOpenModal = () => {
		dispatch( setFormData(initialValues) )
		dispatch( setFormType('store') )
		dispatch( setIsLoadingTable(false) )
		dispatch( setIsOpenModal(true) )
	}
	
	return (
		<>
			<Layout.TableSearch
				permissionList="users.list"
				permissionButton="users.store"
				value={search}
				handleChange={handleChangeSearch}
				handleIsOpenModal={handleIsOpenModal}
				handleKeyPressArrayList={handleKeyPressArrayList}
			/>
			<Permission
				permission="users.list"
			>
				<Layout.TablePagination
					arrayList={arrayList.length}
					perPage={perPage}
					perPages={perPages}
					page={page}
					pages={pages}
					handleChangePerPage={handleChangePerPage}
					handleChangePage={handleChangePage}
				/>
				<Layout.Table>
					<Layout.TableHead
						columns={userData.roles[0] === 'Super Administrador' ? userSuperAdminColumns : userAdminColumns}
					/>
					<tbody>
					{isLoading && (
						<Layout.TableRowLoading
							columns={userData.roles[0] === 'Super Administrador' ? userSuperAdminColumns : userAdminColumns}
						/>
					)}
					{!isLoading && (
						<>
							{arrayList.length === 0 && (
								<Layout.TableRowNoFound
									columns={userData.roles[0] === 'Super Administrador' ? 6 : 5}
								/>
							)}
							{arrayList.length > 0 && (
								arrayList.map(({ id, avatar, first_name, last_name, email, role, company, status, verified }, number) => (
									<TableRowComponent
										key={number}
										id={id}
										avatar={avatar}
										name={`${first_name} ${last_name}`}
										email={email}
										role={role}
										company={company}
										status={status}
										verified={verified}
									/>
								))
							)}
						</>
					)}
					</tbody>
				</Layout.Table>
				<Layout.TablePagination
					arrayList={arrayList.length}
					perPage={perPage}
					perPages={perPages}
					page={page}
					pages={pages}
					handleChangePerPage={handleChangePerPage}
					handleChangePage={handleChangePage}
				/>
			</Permission>
		</>
	)
}

export default TableComponent
