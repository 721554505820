import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import Layout from '../../../../../components/mui/Layout'
import DeleteComponent from './DeleteComponent'
import { setIsLoadingTable, setIsOpenModal, setFormType, getPermissionShowById } from '../../../../../redux/actions/dashboard/settings/permissions/permissionAction'
import { Permission } from '../../../../../services/permissions/Permission'

const TableRowComponent = ({ id, name, publicName, description, group }) => {
	const dispatch = useDispatch()
	const [isOpenModalDelete, setIsOpenModalDelete] = useState(false)
	
	useEffect(() => {
	}, [id, name, publicName, description, group])
	
	const handleIsOpenModal = async () => {
		await dispatch( getPermissionShowById(id) )
		dispatch( setFormType('show') )
		dispatch( setIsLoadingTable(false) )
		dispatch( setIsOpenModal(true) )
	}
	
	const handleIsOpenModalDelete = () => {
		dispatch( setIsLoadingTable(false) )
		setIsOpenModalDelete(true)
	}
	
	return (
		<>
			<tr>
				<td width="20%">
					<div className="font-family-roboto-regular fs-5">{ name }</div>
				</td>
				<td width="25%">
					<div className="font-family-roboto-regular fs-5">{ publicName }</div>
				</td>
				<td width="30%">
					<div className="font-family-roboto-regular fs-5">{ description }</div>
				</td>
				<td width="15%">
					<div className="text-center">
						<div className="font-family-roboto-regular fs-5">{ group }</div>
					</div>
				</td>
				<td width="10%">
					<div className="text-center">
						<Permission
							permission="permissions.show"
						>
							<Layout.Tooltip
								title="Editar"
								placement="top"
							>
								<Layout.IconButton
									color="inherit"
									onClick={handleIsOpenModal}
								>
									<Layout.Icons.EditIcon
										height={16}
										width={16}
										className="text-warning"
									/>
								</Layout.IconButton>
							</Layout.Tooltip>
						</Permission>
						<Permission
							permission="permissions.delete"
						>
							<Layout.Tooltip
								title="Eliminar"
								placement="top"
							>
								<Layout.IconButton
									color="inherit"
									onClick={handleIsOpenModalDelete}
								>
									<Layout.Icons.DeleteIcon
										height={16}
										width={16}
										className="text-danger"
									/>
								</Layout.IconButton>
							</Layout.Tooltip>
						</Permission>
					</div>
				</td>
			</tr>
			<Permission
				permission="permissions.delete"
			>
				<DeleteComponent
					id={id}
					name={name}
					isOpen={isOpenModalDelete}
					setIsOpen={setIsOpenModalDelete}
				/>
			</Permission>
		</>
	)
}

TableRowComponent.propTypes = {
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	publicName: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	group: PropTypes.string.isRequired
}

export default TableRowComponent
