import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Layout from '../../../components/mui/Layout'

const MainPage = () => {

  return (
    <Layout.Page
      title="Escritorio"
    >
      <Card className="border-radius-6">
        <CardContent className="m-0 p-4">
          <div className="row">
            <div className="col-sm-12">
              <div className="font-family-roboto-regular fs-4 text-dark">
                Dashboard
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </Layout.Page>
  )
}

export default MainPage
