import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import Inputs from '../../../../../components/mui/Inputs'
import Layout from '../../../../../components/mui/Layout'
import { setFormData, setCompanyStateStore, setCompanyStateUpdateById } from '../../../../../redux/actions/dashboard/settings/companies/companyAction'
import { Permission } from '../../../../../services/permissions/Permission'
import { initialValuesCompany as initialValues } from '../../../../../helpers/variablesInitialValues'

const FormComponent = () => {
	const dispatch = useDispatch()
	const isLoadingForm = useSelector( state => state.company.isLoadingForm)
	const isLoadingTable = useSelector( state => state.permission.isLoadingTable)
	const formType = useSelector( state => state.company.formType)
	const formData = useSelector( state => state.company.formData)
	
	const validationSchema = () => (
		Yup.object({
			name: Yup.string()
				.required('El nombre es requerido'),
			email: Yup.string()
				.email('El email no es válido')
				.required('El email es requerido'),
			mobile: Yup.string()
				.required('El celular es requerido')
		})
	)
	
	const hanbleSubmit = async (values, { setSubmitting, resetForm, setValues }) => {
		setSubmitting(false)
		
		if (formType === 'store') {
			await dispatch( setCompanyStateStore(formData) )
		} else if (formType === 'show') {
			await dispatch( setCompanyStateUpdateById(formData.id, formData) )
		}
		
		isLoadingTable && resetForm({ values: initialValues })
		isLoadingTable && setValues(initialValues)
	}
	
	const handleOnChange = ({ target }) => dispatch( setFormData( { ...formData, [target.name]: target.value }) )
	
	return (
		<Formik
			initialValues={formData}
			validationSchema={validationSchema}
			onSubmit={hanbleSubmit}
		>
			{({
				  values,
				  errors,
				  handleChange,
				  handleBlur,
				  isValid,
				  dirty
			  }) => (
				<Form noValidate>
					<Inputs.TextBox
						type="text"
						variant="outlined"
						size="small"
						name="name"
						label="Nombre"
						placeholder="Ingrese nombre..."
						hasLabelFixed={true}
						value={formData.name}
						onChange={e => {
							handleOnChange(e)
							handleChange(e)
						}}
						onBlur={handleBlur}
						hasError={errors.name ? true : false}
						errorMessage={errors.name ? errors.name : ''}
						className="font-family-roboto-medium text-dark fs-5 w-100"
					/>
					
					<Inputs.TextBox
						type="email"
						variant="outlined"
						size="small"
						name="email"
						label="Email"
						placeholder="Ingrese email..."
						hasLabelFixed={true}
						value={formData.email}
						onChange={e => {
							handleOnChange(e)
							handleChange(e)
						}}
						onBlur={handleBlur}
						hasError={errors.email ? true : false}
						errorMessage={errors.email ? errors.email : ''}
						className="font-family-roboto-medium text-dark fs-5 w-100 mt-3"
					/>
					
					<Inputs.TextBox
						type="text"
						variant="outlined"
						size="small"
						name="mobile"
						label="Celular"
						placeholder="Ingrese celular..."
						hasLabelFixed={true}
						value={formData.mobile}
						onChange={e => {
							handleOnChange(e)
							handleChange(e)
						}}
						onBlur={handleBlur}
						hasError={errors.mobile ? true : false}
						errorMessage={errors.mobile ? errors.mobile : ''}
						className="font-family-roboto-medium text-dark fs-5 w-100 mt-3"
					/>
					
					<Inputs.TextBox
						type="text"
						variant="outlined"
						size="small"
						name="address"
						label="Dirección"
						placeholder="Ingrese dirección..."
						hasLabelFixed={true}
						value={formData.address}
						onChange={e => {
							handleOnChange(e)
							handleChange(e)
						}}
						onBlur={handleBlur}
						className="font-family-roboto-medium text-dark fs-5 w-100 mt-3"
					/>
					
					<Permission
						permission={`${formType === 'store' ? 'companies.store' : 'companies.update'}`}
					>
						<Inputs.LoadingButton
							isDisabled={formType === 'store' ? !(dirty && isValid) : false}
							variant="contained"
							address="primary"
							isLoading={isLoadingForm}
							isLoadingPosition="start"
							startIcon={formType === 'store' ? <Layout.Icons.SaveIcon height={18} width={18} /> : <Layout.Icons.EditIcon height={18} width={18} />}
							label={`${formType === 'store' ? 'Guardar' : 'Actualizar'}`}
							type="submit"
							className="font-family-roboto-regular fs-6 mt-2 d-flex float-end"
						/>
					</Permission>
				</Form>
			)}
		</Formik>
	)
}

export default FormComponent
